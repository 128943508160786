import { Utils } from 'mw-style-react';

export const GET_REACTIONS = Utils.createRequestTypes('GET_REACTIONS');
export const CREATE_REACTION = Utils.createRequestTypes('CREATE_REACTION');
export const UPDATE_REACTION = Utils.createRequestTypes('UPDATE_REACTION');
export const DELETE_REACTION = Utils.createRequestTypes('DELETE_REACTION');
export const MARK_AS_READ_REACTION = Utils.createRequestTypes(
  'MARK_AS_READ_REACTION'
);
export const CHANGE_REACTION_ORDER = Utils.createRequestTypes(
  'CHANGE_REACTION_ORDER'
);
export const WS_CREATE_REACTION = 'WS_CREATE_REACTION';
export const WS_UPDATE_REACTION = 'WS_UPDATE_REACTION';
export const WS_DELETE_REACTION = 'WS_DELETE_REACTION';
export const WS_SYNC_REACTIONS = 'WS_SYNC_REACTIONS';
export const WS_READ_REACTION = 'WS_READ_REACTION';
export const WS_RENAME_USER = 'WS_RENAME_USER';

export const CLEAR_REACTIONS = 'CLEAR_REACTIONS';
export const SET_REACTIONS_REQ_STATUS = 'SET_REACTIONS_REQ_STATUS';

export const _REACTIONS_ORDER_ = 'reactionsOrderDesc';
export const REACTIONS_REQ_LIMIT = 30;
export const WS_CDU_CHANGES = 'WS_CDU_CHANGES';

// Custom ws events recevied form corezoid
export const WS_EXTERNAL_PACKET = 'WS_EXTERNAL_PACKET';

// Possible actions received by WS_EXTERNAL_PACKET
export const EXTERNAL_PACKET_ACTION = {
  'events.setActive': 'events.setActive',
  'reactions.placeholder': 'reactions.placeholder',
};

export const REACTION_STORAGE_KEY = 'typedReaction_';
