import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Icon,
  Label,
  Tooltip,
  PopoverBoundary,
} from 'mw-style-react';
import useIntl from 'useIntl';
import SelectActorsTemplate from '@control-front-end/common/components/SelectActorsTemplate';
import FormNavigation from '@control-front-end/common/components/FormNavigation';
import FormUtils from '@control-front-end/utils/formUtils';
import BaseItem from './Items/BaseItem';
import mes from './intl';
import './FormEdit.scss';

function FormEdit(props) {
  const t = useIntl();
  const {
    isNew,
    sections,
    onCreateSection,
    parentId,
    formRef,
    onParentIdChange,
    onRefChange,
    isIframe,
    activeSection,
    activeItem,
    errors,
    onActive,
    onChange,
    onCreateItem,
    onRemoveItem,
    onSortItem,
    onShowPanel,
    showPanel,
    errorsChecked,
    onRemoveSection,
    onRenameSection,
    onSortSection,
  } = props;
  const sectionsListRef = useRef();
  const [initialParent, setInitialParent] = useState();
  const isAlreadyUAT = !isNew && !initialParent;
  const isIframeChildForm = isIframe && !!parentId;

  const { boundaryRef } = useContext(PopoverBoundary.Context);

  useEffect(() => {
    boundaryRef.current = sectionsListRef.current;
    if (parentId) setInitialParent(parentId);
  }, []);

  // Установить индексы в модель
  const setItemIndex = (items, sectionIndex) => {
    items.forEach((item, index) => {
      item.itemIndex = index;
      item.sectionIndex = sectionIndex;
    });
    return items;
  };

  // Групировка элементов формы в строку
  const groupRowItems = (items) => {
    const newItems = [];
    const addedRows = [];
    items.forEach((item) => {
      const rowId = item.row;
      if (!rowId) {
        newItems.push(item);
      } else if (!addedRows.includes(rowId)) {
        const rowItems = items.filter((i) => i.row === rowId);
        newItems.push({
          class: 'row',
          id: `row_${rowId}`,
          items: rowItems,
          rowCount: rowItems.length,
        });
        addedRows.push(rowId);
      }
    });
    return newItems;
  };

  const handleSelectSection = (sectionIndex) => {
    if (sectionIndex !== -1) onActive({ sectionIndex, itemIndex: 0 });
    FormUtils.scrollToFormSection(sectionsListRef.current, null, sectionIndex);
  };

  // Отрисовать элементы формы
  const renderItems = ({
    items,
    sectionsLen,
    itemsLen,
    rowCount,
    activeRow,
    isRow,
    untitledSection,
  }) => {
    return items.map((item) => {
      if (item.class === 'row') {
        return (
          <div key={item.id} styleName="fe__row">
            {renderItems({
              items: item.items,
              sectionsLen,
              itemsLen,
              rowCount: item.rowCount,
              activeRow,
              isRow: true,
              untitledSection,
            })}
          </div>
        );
      }
      return (
        <BaseItem
          activeSection={activeSection}
          activeItem={activeItem}
          activeRow={activeRow}
          onActive={onActive}
          onChange={onChange}
          onCreateItem={onCreateItem}
          onRemoveItem={onRemoveItem}
          onSortItem={onSortItem}
          onShowPanel={onShowPanel}
          showPanel={showPanel}
          rowCount={rowCount}
          sections={sections}
          sectionsLen={sectionsLen}
          itemsLen={itemsLen}
          focus={true}
          rowItems={isRow ? items : undefined}
          errors={errors}
          {...item}
        />
      );
    });
  };

  // Отрисовать секции
  const renderSections = (sections) => {
    const sectionsLen = sections.length;
    const selectedSection = sections[activeSection] || {};
    const selectedSectionContent = selectedSection.content || [];
    const activeItemModel = selectedSectionContent[activeItem] || {};
    const activeRow = activeItemModel.row;
    return sections.map((section, index) => {
      const items = setItemIndex(section.content || [], index);
      const itemsLen = items.length;
      const sectionItems = groupRowItems(items);
      const sectionTitle = section.title || '';
      const untitledSection = !sectionTitle.length;
      const titleFieldError = errorsChecked && !sectionTitle.length;
      return (
        <div key={index} id={`section_${index}`} styleName="fe__section">
          <div styleName="fe__section__header">
            <TextField
              styleName="fe__section__header__title"
              className="section__title"
              size="small"
              bordered={true}
              autoFocus={untitledSection}
              placeholder={t(mes.enterSectionTitle)}
              helperText={titleFieldError ? t(mes.fieldRequired) : null}
              error={titleFieldError}
              value={sectionTitle}
              onChange={({ value }) => onRenameSection(index, value)}
            />
            <div styleName="fe__section__header__btns">
              <div styleName="fe__section__header__btns__nav">
                <Tooltip topLevel value={t(mes.moveSectionUp)}>
                  <Icon
                    type="arrow"
                    visibility={index !== 0 ? 'visible' : 'disabled'}
                    onClick={() => onSortSection(index, index - 1)}
                  />
                </Tooltip>
                <Tooltip topLevel value={t(mes.moveSectionDown)}>
                  <Icon
                    type="arrow"
                    visibility={
                      index !== sectionsLen - 1 ? 'visible' : 'disabled'
                    }
                    onClick={() => onSortSection(index, index + 1)}
                  />
                </Tooltip>
              </div>
              <div>
                <Icon type="trash" onClick={() => onRemoveSection(index)} />
              </div>
            </div>
          </div>
          <div styleName="fe__section__content">
            {renderItems({
              items: sectionItems,
              sectionsLen,
              itemsLen,
              rowCount: 0,
              activeRow,
              isRow: false,
              untitledSection,
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div styleName="fe">
      <div styleName="fe__ref">
        <Label fontWeight="semibold" value={t(mes.formRef)} />
        <TextField
          className="section__title"
          bordered={true}
          value={formRef || ''}
          onChange={({ value }) => onRefChange(value)}
        />
      </div>
      <div styleName="fe__parent">
        <Label fontWeight="semibold" value={t(mes.selectParentForm)} />
        <SelectActorsTemplate
          bordered={true}
          value={{ value: parentId }}
          withDefault={false}
          formTypes="all"
          withRelations={true}
          isParent={true}
          onChange={({ value }) => onParentIdChange(value.id)}
          placeholder={isAlreadyUAT ? t(mes.noParentForUAT) : ''}
          isDisabled={isAlreadyUAT || isIframeChildForm}
        />
        <Icon
          styleName="fe__parent__clear"
          size="small"
          type="close"
          onClick={() => onParentIdChange(null)}
          visibility={parentId && !isIframeChildForm ? 'visible' : 'hidden'}
        />
      </div>
      <div styleName="fe__sections">
        <div styleName="fe__sections__navigation">
          <FormNavigation
            sections={sections}
            activeSection={activeSection}
            readOnly={false}
            handleSelect={handleSelectSection}
            handleSortSection={onSortSection}
            handleRemoveSection={onRemoveSection}
          />
        </div>
        <div ref={sectionsListRef} styleName="fe__sections__list">
          {renderSections(sections)}
          <div styleName="fe__add" onClick={onCreateSection}>
            <div>{t(mes.addSection)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

FormEdit.propTypes = {
  sections: PropTypes.array.isRequired,
  activeSection: PropTypes.number.isRequired,
  activeItem: PropTypes.number.isRequired,
  errors: PropTypes.object,
  errorsChecked: PropTypes.bool,
  isNew: PropTypes.bool,
  isIframe: PropTypes.bool,
  formRef: PropTypes.string,
  parentId: PropTypes.number,
  onActive: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onParentIdChange: PropTypes.func.isRequired,
  onRefChange: PropTypes.func.isRequired,
  onCreateSection: PropTypes.func.isRequired,
  onRemoveSection: PropTypes.func.isRequired,
  onSortSection: PropTypes.func.isRequired,
  onRenameSection: PropTypes.func.isRequired,
  onCreateItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onSortItem: PropTypes.func.isRequired,
  onShowPanel: PropTypes.func.isRequired,
};

export default FormEdit;
