import { createSelector } from '@reduxjs/toolkit';

const getTemplate = (state, props) => {
  const formList = state.formList;
  return formList.list.find((item) => item.id === props.id);
};

const findLayer = (graphLayers) => {
  const { active, list } = graphLayers;
  if (!active) return null;
  return list.find((item) => item.id.toString() === active.toString());
};

export const makeGetTemplate = createSelector(
  [getTemplate],
  (template) => template
);

export const makeGetActiveActor = createSelector(
  (state) => state.actorsList,
  (actorsList) => {
    const { activeActor, list } = actorsList;
    if (!activeActor) return null;
    return list.find((item) => item.id.toString() === activeActor.toString());
  }
);

export const makeGetActiveElement = createSelector(
  (state) => state.graphLayers,
  (graphLayers) => {
    const layer = findLayer(graphLayers);
    if (!layer) return null;
    const { activeElement, activeType } = layer;
    if (!activeElement) return null;
    const type = activeType === 'edge' ? 'edges' : 'nodes';
    return layer[type].find(
      (item) => item.id.toString() === activeElement.toString()
    );
  }
);

export const makeGetActiveLayer = createSelector(
  (state) => state.graphLayers,
  (graphLayers) => findLayer(graphLayers)
);

/**
 * Получить новые элементы графа
 */
export const getGraphElements = createSelector(
  (state) => state.graphLayers,
  (graphLayers) => {
    const activeLayer = findLayer(graphLayers);
    if (!activeLayer) return [];
    const els = (activeLayer.nodes || []).concat(activeLayer.edges || []);
    const filtered = els.filter((i) => !!i.status);
    return structuredClone(filtered);
  }
);

/**
 * Получить зоны с комментариями на слое
 */
export const getLayerAreas = createSelector(
  (state) => state.graphLayers,
  (graphLayers) => {
    const activeLayer = findLayer(graphLayers);
    if (!activeLayer) return [];
    const layerAreas = (activeLayer.nodes || []).filter(
      (i) => !!i.data.areaPicture && !!i.isLayerArea
    );
    return structuredClone(layerAreas);
  }
);

const getFolder = (state, props) => {
  if (!props.graphFolderId) return {};
  const { list } = state.graphFolders;
  return list.find((item) => item.id === props.graphFolderId) || {};
};

/**
 * Получить активный граф
 */
export const makeGetActiveGraph = createSelector(
  [getFolder],
  (folder) => folder
);

/**
 * Get hierarchical edgeType ID
 */
export const getHierarchyEdgeTypeId = createSelector(
  (state) => state.edgeTypes,
  (edgeTypes) => {
    return edgeTypes.find((i) => i.name === 'hierarchy').id;
  }
);
