import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select,
  MenuItem,
  Button,
  Icon,
  Badge,
  Divider,
  Label,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import { GET_FORM_ITEMS_TITLES } from '@control-front-end/common/constants/forms';
import AppUtils from '@control-front-end/utils/utils';
import FieldValue from './components/FieldValue/FieldValue';
import mes from './intl';
import '../../CreateActorsFilter.scss';

const VISIBLE_LABEL_LENGTH = 28;
const VALUE_SERIALIZER = {
  calendar: {
    serialize: (value) => {
      return value ? JSON.stringify({ startDate: value }) : null;
    },
    deserialize: (value) => {
      try {
        const parsedValue = JSON.parse(value);
        return parsedValue.startDate;
      } catch {
        return value;
      }
    },
  },
};

/**
 * Serializes/deserializes field value depending on field class
 */
const getFieldValue = ({ value, class: fieldClass }, action) => {
  if (action && VALUE_SERIALIZER[fieldClass]) {
    return VALUE_SERIALIZER[fieldClass][action](value);
  }
  return value;
};

const getFieldsErrors = (fields) =>
  fields.reduce((result, { id, separator, value }) => {
    // Set an error for each empty filter attribute of the field
    result[id] = {
      id: id === 'tmp',
      separator: !separator,
      value: !value,
    };
    return result;
  }, {});

const checkIsThereAnyErrors = (fields) =>
  Boolean(
    Object.values(getFieldsErrors(fields)).find(
      ({ id, separator }) => id || separator
    )
  );

function FilterItem({
  filterField,
  formField,
  errors,
  formId,
  separators,
  availableFields,
  index,
  onChange,
  onRemove,
}) {
  const t = useIntl();
  const eventSystemForm = useSelector((state) => state.systemForms.events);

  const handleChangeValue = useCallback(
    ({ value }) => onChange({ value }, index),
    []
  );

  const handleChangeSeparator = useCallback(
    ({ value }) => onChange({ separator: value }, index),
    []
  );

  const handleChangeFieldId = useCallback(
    ({ value }) => {
      // Workaround: cause server accepts only integers for system events form
      const isSystemFormCalendar =
        value.formId === eventSystemForm.id && value.class === 'calendar';

      onChange(
        {
          id: value.key,
          value: '',
          separator: '=',
          class: isSystemFormCalendar ? 'edit' : value.class,
        },
        index
      );
    },
    [formField]
  );

  const handleRemove = () => onRemove(index);

  return (
    <div styleName="filter__row field">
      <div styleName="filter__item">
        <Select
          styleName="filter__select openDown"
          type="autocomplete"
          autoSelect={false}
          size="medium"
          bordered={true}
          placeholder={t(mes.actorTmplKey)}
          label={t(mes.field)}
          value={formField.title || ''}
          onChange={handleChangeFieldId}
          error={errors?.id}
        >
          <MenuItem value="tmp" label="" />
          {availableFields.map((i) => (
            <MenuItem
              key={i.key}
              styleName="filter__select__item"
              value={i}
              label={i.title}
              labelTooltip={i.title.length > VISIBLE_LABEL_LENGTH}
            >
              <div styleName="filter__select__item__form">
                <Badge
                  size="small"
                  bgColor={i.formColor || '#ffffff'}
                  borderColor={i.formColor || '#ACB3BE'}
                />
                <Label
                  fontSize="small"
                  value={i.formTitle}
                  duplicatingTooltip={i.formTitle.length > VISIBLE_LABEL_LENGTH}
                />
                <Divider styleName="filter__select__item__divider" />
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
      <div styleName="filter__item" style={{ width: 140 }}>
        <Select
          styleName="filter__select openDown"
          size="medium"
          bordered={true}
          value={filterField.separator}
          onChange={handleChangeSeparator}
          error={errors?.separator}
        >
          {separators.map((i) => (
            <MenuItem key={i} value={i} label={i} />
          ))}
        </Select>
      </div>
      <div styleName="filter__item action">
        <FieldValue
          {...filterField}
          value={filterField.value?.toString()}
          formId={formId}
          index={index}
          fieldItem={formField}
          handleChangeField={handleChangeValue}
          error={errors?.value}
        />
        <div onClick={handleRemove}>
          <Icon type="close" size="small" />
        </div>
      </div>
    </div>
  );
}

const SEPARATORS = ['<=', '>=', '!=', '<', '>', '='];

const EMPTY_ITEM = { id: 'tmp', value: '', title: '', separator: '=' };

/**
 * Формирование поискового запроса
 */
function TemplateFields(props) {
  const {
    formId,
    q = '',
    formFields = [],
    readOnly = false,
    onChange,
    makeFieldKey,
    displayErrors,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const isFirstRenderRef = useRef(true);

  const eventSystemForm = useSelector((state) => state.systemForms.events);

  const [fields, setFields] = useState([EMPTY_ITEM]);

  const [fieldsError, setFieldsError] = useState({});

  /**
   * Парсинг строки q на ключ-значение
   */
  const parseQ = () => {
    const formFieldsKeys = { tmp: EMPTY_ITEM };
    formFields.forEach((field) => {
      formFieldsKeys[field.key] = field;
    });
    const splitAnd = decodeURIComponent(q).split('&');
    const makedQ = [];
    for (const keyValue of splitAnd) {
      for (const separator of SEPARATORS) {
        const split = keyValue.split(separator);
        if (split.length < 2) continue;
        // split only on first instance of separator
        const [parsedKey, ...rest] = split;
        const parsedValue = rest.join(separator);
        makedQ.push({
          id: makeFieldKey(parsedKey, formId),
          value: parsedValue,
          title: '',
          separator,
        });
        break;
      }
    }
    if (!formId || !makedQ.length) return;
    const newItems = AppUtils.diffArrayObj(fields, makedQ, 'id');
    if (!newItems.length) {
      const deletedFields = fields.filter((item) => !formFieldsKeys[item.id]);
      if (deletedFields.length) {
        const actualFields = fields.filter((item) => !!formFieldsKeys[item.id]);
        setFields(actualFields);
      }
      return;
    }
    dispatch({
      type: GET_FORM_ITEMS_TITLES.REQUEST,
      payload: { formId, items: makedQ },
      callback: (data) => {
        const actualFields = data
          .filter((item) => !!formFieldsKeys[item.id])
          .map((item) => {
            const formField = formFieldsKeys[item.id];
            // Workaround: cause server accepts only integers for system events form
            const isSystemFormCalendar =
              formField.formId === eventSystemForm.id &&
              formField.class === 'calendar';
            return {
              ...item,
              value: getFieldValue(
                {
                  value: item.value,
                  class: isSystemFormCalendar ? 'edit' : formField.class,
                },
                'deserialize'
              ),
              class: isSystemFormCalendar ? 'edit' : formField.class,
            };
          });
        setFields(actualFields);
      },
    });
  };

  /**
   * Получить доступные операции сравнения
   */
  const getSeparatorsList = (key) => {
    const lessSep = ['!=', '='];
    const field = formFields.find((item) => item.key === key);
    if (!field) return lessSep;
    const numTypes = ['int', 'float'];
    if (
      (field.class === 'edit' && numTypes.includes(field.type)) ||
      field.class === 'calendar'
    ) {
      return SEPARATORS;
    }
    return lessSep;
  };

  useEffect(() => {
    if (q && formFields.length) {
      parseQ();
    }
  }, [q, formFields]);

  /**
   * Добавление поля формы
   */
  const handleAddItem = () => {
    const newFields = [...fields, EMPTY_ITEM];
    setFields(newFields);
  };

  /**
   * Удаление поля формы
   */
  const handleDelItem = (index) => {
    const newFields = fields.slice();
    newFields.splice(index, 1);
    setFields(newFields);
  };

  useEffect(() => {
    const errors = getFieldsErrors(fields);
    return setFieldsError(displayErrors ? errors : {});
  }, [fields, displayErrors]);

  const handleFilterItemChange = useCallback(
    (changes, index) =>
      setFields((fields) => {
        const newFields = fields.slice();
        newFields.splice(index, 1, { ...newFields[index], ...changes });
        return newFields;
      }),
    []
  );

  useEffect(() => {
    // Skip first render and trigger call-back when something is really changed
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    // Building "q" string for the filter
    const value = fields
      .map((i) =>
        encodeURIComponent(
          `${i.id}${i.separator}${getFieldValue(i, 'serialize')}`
        )
      )
      .join('&');
    onChange({
      id: 'q',
      value,
      error: checkIsThereAnyErrors(fields),
    });
  }, [fields]);

  if (!formId) return null;
  return (
    <>
      <div styleName="filter__section dynamic">
        {fields.length ? (
          <div styleName="filter__col">
            {fields.map((filterField, index) => (
              <FilterItem
                key={`${filterField.id}_${index}`}
                formId={formId}
                filterField={filterField}
                formField={
                  formFields.find((item) => item.key === filterField.id) || {}
                }
                index={index}
                errors={displayErrors && fieldsError[filterField.id]}
                separators={getSeparatorsList(filterField.id)}
                availableFields={formFields}
                onChange={handleFilterItemChange}
                onRemove={() => handleDelItem(index)}
              />
            ))}
          </div>
        ) : null}
      </div>
      {!readOnly ? (
        <div styleName="filter__section dynamic">
          <Button
            styleName="filter__btn"
            type={Button.TYPE.quaternary}
            size="smallplus"
            fontWeight="normal"
            icon="add"
            label={t(mes.addField)}
            onClick={handleAddItem}
          />
        </div>
      ) : null}
    </>
  );
}

TemplateFields.propTypes = {
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q: PropTypes.string,
  displayErrors: PropTypes.bool,
  onChange: PropTypes.func,
  makeFieldKey: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default TemplateFields;
