import { useState, useEffect } from 'react';

const useDrag = (ref, onDrag, onUp, onDown, isDraggable = true) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseUp = (e) => {
    document.onmousemove = null;
    document.onmouseup = null;
    document.onselectstart = () => true;
    setIsDragging(false);
    if (onUp) onUp(e);
  };

  const handleMouseMove = (e) => {
    setIsDragging(true);
    onDrag(e);
  };

  const handleMouseDown = (e) => {
    document.onmousemove = handleMouseMove;
    document.onmouseup = handleMouseUp;
    document.onselectstart = () => false;
    if (onDown) onDown(e);
  };

  useEffect(() => {
    if (!isDraggable) return;
    const element = ref.current;
    if (element) {
      element.addEventListener('mousedown', handleMouseDown);
      element.addEventListener('mouseup', handleMouseUp);

      return () => {
        element.removeEventListener('mousedown', handleMouseDown);
        element.removeEventListener('mouseup', handleMouseUp);
      };
    }

    return () => {};
  }, [ref.current, isDraggable, onDrag]);

  return { isDragging };
};

export default useDrag;
