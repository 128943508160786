import { Utils } from 'mw-style-react';
import { TIMEFRAME_NAME } from './index';

export const GET_ACTORS = Utils.createRequestTypes('GET_ACTORS');
export const GET_ITEMS_BY_IDS = Utils.createRequestTypes('GET_ITEMS_BY_IDS');
export const GET_ACTOR = Utils.createRequestTypes('GET_ACTOR');
export const GET_ACTOR_VIEW_DATA = Utils.createRequestTypes(
  'GET_ACTOR_VIEW_DATA'
);
export const GET_ACTOR_BY_REF = Utils.createRequestTypes('GET_ACTOR_BY_REF');
export const GET_LINKED_ACTORS = Utils.createRequestTypes('GET_LINKED_ACTORS');
export const GET_LINKED_FORMS = Utils.createRequestTypes('GET_LINKED_FORMS');
export const SEARCH_LINKED_ACTORS = Utils.createRequestTypes(
  'SEARCH_LINKED_ACTORS'
);
export const CREATE_ACTOR = Utils.createRequestTypes('CREATE_ACTOR');
export const UPDATE_ACTOR = Utils.createRequestTypes('UPDATE_ACTOR');
export const COPY_ACTOR = Utils.createRequestTypes('COPY_ACTOR');
export const BULK_UPDATE_ACTORS_FIELD = Utils.createRequestTypes(
  'BULK_UPDATE_ACTORS_FIELD'
);
export const BULK_UPDATE_ACTORS_SETTINGS = Utils.createRequestTypes(
  'BULK_UPDATE_ACTORS_SETTINGS'
);
export const REMOVE_ACTOR_FORMS =
  Utils.createRequestTypes('REMOVE_ACTOR_FORMS');
export const REMOVE_ALL_FORM_ACTORS = Utils.createRequestTypes(
  'REMOVE_ALL_FORM_ACTORS'
);
export const GET_EDGE = Utils.createRequestTypes('GET_EDGE');
export const CREATE_EDGE = Utils.createRequestTypes('CREATE_EDGE');
export const CREATE_MASS_EDGES = Utils.createRequestTypes('CREATE_MASS_EDGES');
export const GET_EDGE_TYPES = Utils.createRequestTypes('GET_EDGE_TYPES');
export const GET_EDGE_TYPE = Utils.createRequestTypes('GET_EDGE_TYPE');
export const STARRED_ACTOR = Utils.createRequestTypes('STARRED_ACTOR');
export const UPDATE_ACTOR_VIEW = Utils.createRequestTypes('UPDATE_ACTOR_VIEW');
export const SET_ACTOR_VIEW_REQ_STATUS = 'SET_ACTOR_VIEW_REQ_STATUS';
export const GET_ACTOR_GLOBAL_LINKS = Utils.createRequestTypes(
  'GET_ACTOR_GLOBAL_LINKS'
);
export const SET_ACTIVE_ELEMENT =
  Utils.createRequestTypes('SET_ACTIVE_ELEMENT');
export const SET_DEFAULT_ACTOR_TPL = 'SET_DEFAULT_ACTOR_TPL';
export const GET_ACTOR_TPL = Utils.createRequestTypes('GET_ACTOR_TPL');
export const UPDATE_EDGE = Utils.createRequestTypes('UPDATE_EDGE');
export const REMOVE_EDGE = Utils.createRequestTypes('REMOVE_EDGE');
export const REMOVE_NODES_EDGE = Utils.createRequestTypes('REMOVE_NODES_EDGE');
export const REMOVE_ACTOR = Utils.createRequestTypes('REMOVE_ACTOR');
export const SEARCH_ACTORS = Utils.createRequestTypes('SEARCH_ACTORS');
export const ADD_ACTOR = Utils.createRequestTypes('ADD_ACTOR');
export const BULK_ADD_TO_GRAPH = Utils.createRequestTypes('BULK_ADD_TO_GRAPH');
export const BULK_REMOVE_FROM_GRAPH = Utils.createRequestTypes(
  'BULK_REMOVE_FROM_GRAPH'
);
export const GET_ALL_ACTORS = Utils.createRequestTypes('GET_ALL_ACTORS');
export const GET_TEMPLATE_ACTORS = Utils.createRequestTypes(
  'GET_TEMPLATE_ACTORS'
);
export const GET_TEMPLATE_ACTORS_EVERY = Utils.createRequestTypes(
  'GET_TEMPLATE_ACTORS_EVERY'
);

export const ADD_ACTOR_ATTACHES =
  Utils.createRequestTypes('ADD_ACTOR_ATTACHES');
export const REMOVE_ACTOR_ATTACHES = Utils.createRequestTypes(
  'REMOVE_ACTOR_ATTACHES'
);
export const DOWNLOAD_ACTOR_ATTACHES = Utils.createRequestTypes(
  'DOWNLOAD_ACTOR_ATTACHES'
);

export const CREATE_ACTOR_LIST = Utils.createRequestTypes('CREATE_ACTOR_LIST');
export const UPDATE_ACTOR_UNREAD_REACTION = Utils.createRequestTypes(
  'UPDATE_ACTOR_UNREAD_REACTION'
);
export const GET_ACTORS_LIST = Utils.createRequestTypes('GET_ACTORS_LIST');
export const UPDATE_ACTOR_LIST = Utils.createRequestTypes('UPDATE_ACTOR_LIST');
export const REMOVE_ACTOR_LIST = Utils.createRequestTypes('REMOVE_ACTOR_LIST');
export const SEND_ACTORS_DATA = Utils.createRequestTypes('SEND_ACTORS_DATA');
export const ACTORS_ACCESS = Utils.createRequestTypes('ACTORS_ACCESS');
export const VALENCY_GRAPH = {
  GET: Utils.createRequestTypes('VALENCY_GRAPH_GET'),
  REMOVE_NODES: Utils.createRequestTypes('VALENCY_GRAPH_REMOVE_NODES'),
};
export const GET_ACTORS_BALANCE =
  Utils.createRequestTypes('GET_ACTORS_BALANCE');
export const COPY_FROM_GRAPH = Utils.createRequestTypes('COPY_FROM_GRAPH');
export const PASTE_TO_GRAPH = Utils.createRequestTypes('PASTE_TO_GRAPH');

export const SET_ACTIVE_ACTOR = 'SET_ACTIVE_ACTOR';
export const SET_ACTORS_REQ_STATUS = 'SET_ACTORS_REQ_STATUS';
export const CLEAR_LIST_ACTORS = 'CLEAR_LIST_ACTORS';
export const CLEAR_ACTOR = 'CLEAR_ACTOR';

export const GRAPH_NODE_SIZE = 50;
export const EXPANDED_GRAPH_NODE_SIZE = {
  width: 450,
  height: 300,
};
export const MIN_PANEL_WIDTH = 516;
export const MAX_PANEL_WIDTH = 640;
export const MAX_EVENT_PANEL_WIDTH = 1260;
export const DEFAULT_EVENT_PANEL_WIDTH = 1040;
export const MAX_EVENT_PANEL_WIDTH_RATIO = 0.85;
export const DEFAULT_EVENT_PANEL_WIDTH_RATIO = 0.7;
export const DAGREE_NODE_SEP = 168;
export const AUTO_LAYER_PAGE_LIMIT = 25;
export const EMPTY_IMG_BASE64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAABhJREFUKFNjZCARMJKonmFUAzEhNhxCCQAROgANnR/sPAAAAABJRU5ErkJggg=='; // eslint-disable-line max-len

// ACTOR CONTENT TABS
export const ActorTab = Object.freeze({
  details: 'details',
  info: 'info',
  chat: 'chat',
  code: 'code',
  people: 'people',
  attachments: 'attachments',
  accounts: 'accounts',
  events: 'events',
  actors: 'actors',
});

// SYSTEM ACTORS
export const SAVE_SYSTEM_ACTOR = Utils.createRequestTypes('SAVE_SYSTEM_ACTOR');
export const GET_SYSTEM_ACTOR = Utils.createRequestTypes('GET_SYSTEM_ACTOR');
export const CHANGE_SYSTEM_ACTOR = Utils.createRequestTypes(
  'CHANGE_SYSTEM_ACTOR'
);
export const NON_FILTER_KEYS = [
  'withForm',
  'lastReaction',
  'reactionsCount',
  'reactionsStats',
  'isUat',
];
export const SYSTEM_ACTOR_CUSTOM_MODALS = {
  Scripts: 'CreateScriptApp',
  Events: 'EventCard',
  ActorFilters: 'CreateActorsFilter',
  TransactionFilters: 'CreateTransactionsFilter',
  TransferFilters: 'CreateTransactionsFilter',
  Dashboards: 'CreateDashboard',
  CompanyCard: 'CreateCompanyCardActor',
};

// DASHBOARDS
export const PM_DASHBOARD_SET_SOURCE = 'PM_DASHBOARD_SET_SOURCE';
export const PM_DASHBOARD_READY = 'PM_DASHBOARD_READY';
export const GET_DASHBOARD = Utils.createRequestTypes('GET_DASHBOARD');
export const GET_DASHBOARD_ACCOUNTS = Utils.createRequestTypes(
  'GET_DASHBOARD_ACCOUNTS'
);
export const DASHBOARD_VIEW_MODES = Object.freeze({
  default: 'default',
  balanceChanges: 'balanceChanges',
});
export const DEFAULT_DASHBOARD = Object.freeze({
  title: 'New Dashboard',
  counterType: 'amount',
  chartType: 'pie',
  chartViewMode: DASHBOARD_VIEW_MODES.default,
  sourceType: 'manual',
  orderValue: 'desc',
  range: 'allTime',
  displayChartDataLabels: true,
  showTotal: true,
  legend: { actorTitle: true, accountName: true, currencyName: true },
});
export const DASHBOARD_CHART_TYPES = Object.freeze({
  BAR: 'bar',
  STACKED_BAR: 'stackedBar',
  LINE: 'line',
  PIE: 'pie',
  DOUGHNUT: 'doughnut',
  FUNNEL: 'funnel',
  TABLE: 'table',
});
export const LINED_CHART_TYPES = [
  DASHBOARD_CHART_TYPES.LINE,
  DASHBOARD_CHART_TYPES.STACKED_BAR,
];
export const DASHBOARD_SOURCE_TYPES = Object.freeze({
  MANUAL: 'manual',
  ACTOR_FILTER: 'actorFilter',
});
export const DASHBOARD_DATA_INTERVAL = Object.freeze({
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
});
export const DEFAULT_DASHBOARD_LEGEND = Object.freeze({
  actorTitle: true,
  accountName: true,
  currencyName: true,
  incomeType: false,
});
export const DASHBOARD_RANGES = [
  TIMEFRAME_NAME.allTime,
  TIMEFRAME_NAME.lastMinute,
  TIMEFRAME_NAME.last10Minutes,
  TIMEFRAME_NAME.lastHour,
  TIMEFRAME_NAME.today,
  TIMEFRAME_NAME.yesterday,
  TIMEFRAME_NAME.lastWeek,
  TIMEFRAME_NAME.lastMonth,
  TIMEFRAME_NAME.previousMonth,
  TIMEFRAME_NAME.realTime,
  TIMEFRAME_NAME.lineRealTime,
];

export const DEFAULT_RANGES = [
  TIMEFRAME_NAME.today,
  TIMEFRAME_NAME.yesterday,
  TIMEFRAME_NAME.lastWeek,
  TIMEFRAME_NAME.lastMonth,
  TIMEFRAME_NAME.previousMonth,
  TIMEFRAME_NAME.custom,
];

// CACHE ACTORS
export const SET_ACTORS_CACHE = 'CACHE_ACTOR_DATA';
export const CLEAR_ACTORS_CACHE = 'CLEAR_ACTORS_CACHE';

// WS
export const WS_CREATE_ACTOR = 'WS_CREATE_ACTOR';
export const WS_UPDATE_ACTOR = 'WS_UPDATE_ACTOR';
export const WS_DELETE_ACTOR = 'WS_DELETE_ACTOR';
export const WS_CREATE_EDGE = 'WS_CREATE_EDGE';
export const WS_UPDATE_EDGE = 'WS_UPDATE_EDGE';
export const WS_DELETE_EDGE = 'WS_DELETE_EDGE';
export const WS_CREATE_ATTACHMENTS = 'WS_CREATE_ATTACHMENTS';
export const WS_DELETE_ATTACHMENTS = 'WS_DELETE_ATTACHMENTS';
export const WS_ZIP = 'WS_ZIP';

export const ACTORS_COLORS_PALETTE = {
  Cherry: '#EE3939',
  Lips: '#DF1278',
  Peach: '#FA5032',
  Sun: '#FEC603',
  Grass: '#99D31B',
  Neo: '#257C42',
  Sky: '#16D6FA',
  Simulator: '#1973E8',
  Purple: '#9756CA',
  DarkGray: '#12171C',
};

export const ACTORS_LIST_TYPES = {
  RECENT: 'recent',
  TEMPLATE: 'template',
  STREAM: 'stream',
};

/**
 * Delay to use in case of several layer elements updates in a row
 * when Cytoscape.js doesn't have time to apply all changes on canvas
 */
export const DELAY_BEFORE_NEXT_GRAPH_UPDATE = 100;

export const NODE_TYPE = {
  default: 'default',
  picture: 'picture',
  layerArea: 'layerArea',
  state: 'state',
  trace: 'trace',
};

export const NODE_Z_INDEX = {
  [NODE_TYPE.default]: 110,
  [NODE_TYPE.picture]: 108,
  [NODE_TYPE.layerArea]: 107,
  [NODE_TYPE.state]: 106,
  [NODE_TYPE.trace]: 105,
};

export const ReactionOption = Object.freeze({
  smiles: 'smiles',
  snippets: 'snippets',
  script: 'script',
  files: 'files',
  rating: 'rating',
});

export const VALENCY_LINKER_TYPES = {
  parent: 'parent',
  child: 'child',
};
