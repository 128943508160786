import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Banner,
  Button,
  Icon,
  Tooltip,
  Label,
  Space,
  Stack,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import AccAdvancedOptions from '@control-front-end/common/components/AccAdvancedOptions';
import SelectAccountName from '@control-front-end/common/components/SelectAccountName';
import SelectAccountCurrency from '@control-front-end/common/components/SelectAccountCurrency';
import Toggle from '@control-front-end/common/components/Toggle';
import { SAVE_ACCOUNT_PAIR } from '@control-front-end/common/constants/actorAccounts';
import useIntl from 'useIntl';
import mes from './intl';
import './FormAccounts.scss';

function FormAccounts({
  formAccounts,
  formFields,
  handleChangeAccounts,
  handleDeleteField,
  handleDeleteSection,
}) {
  const t = useIntl();
  const dispatch = useDispatch();

  /**
   * Change account options
   */
  const handleChangeItem = ({ id, param, callback }) => {
    const newFormAccounts = formAccounts.slice();
    const itemIndex = newFormAccounts.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedItem = { ...newFormAccounts[itemIndex], ...param };
      newFormAccounts.splice(itemIndex, 1, updatedItem);
      handleChangeAccounts(newFormAccounts);
      if (callback) callback(updatedItem);
    }
  };

  /**
   * Create account pair
   */
  const handleSaveAccPair = (account) => {
    const { nameId, accountName, currencyId, currencyName } = account;
    if (!nameId || !currencyId) return;
    dispatch({
      type: SAVE_ACCOUNT_PAIR.REQUEST,
      payload: { accountName, currencyName },
    });
  };

  /**
   * Add new default account
   */
  const handleAddItem = () => {
    const newFormAccounts = formAccounts.slice();
    const newAccount = {
      id: `new-${AppUtils.createRid()}`,
      action: 'create',
      accountType: 'fact',
      nameId: null,
      currencyId: null,
      treeCalculation: false,
      search: true,
    };
    newFormAccounts.unshift(newAccount);
    handleChangeAccounts(newFormAccounts);
  };

  /**
   * Remove account
   */
  const handleDeleteItem = (id) => {
    const newFormAccounts = formAccounts.slice();
    const itemIndex = newFormAccounts.findIndex((item) => item.id === id);
    if (itemIndex === -1) return;
    const deletedItem = newFormAccounts[itemIndex];
    const isNew = id.toString().indexOf('new-') === 0;
    if (isNew) {
      newFormAccounts.splice(itemIndex, 1);
    } else {
      deletedItem.action = 'delete';
      newFormAccounts.splice(itemIndex, 1, deletedItem);
    }
    handleChangeAccounts(newFormAccounts);
  };

  const getExcludedAccountNames = (currencyId) => {
    return formAccounts
      .filter(
        (acc) => acc.nameId && acc.currencyId && acc.currencyId === currencyId
      )
      .map((i) => i.nameId);
  };

  const getExcludedCurrencies = (nameId) => {
    return formAccounts
      .filter((acc) => acc.nameId && acc.currencyId && acc.nameId === nameId)
      .map((i) => i.currencyId.toString());
  };

  /**
   * Info banner about no access to account pair
   */
  const renderNoAccessError = () => (
    <Banner
      styleName="l.block l.noAccess"
      size="medium"
      error={true}
      value={t(mes.noPairAccess)}
    />
  );

  const renderDeleteButton = (tooltipLabel, onClick) => (
    <div onClick={onClick}>
      <Tooltip topLevel value={tooltipLabel}>
        <Icon styleName="fa__section__close" type="trash" />
      </Tooltip>
    </div>
  );

  const renderAccountRow = (formAcc) => {
    const {
      id,
      error,
      action,
      currencyId,
      nameId,
      accountName,
      currencyName,
      minLimit,
      maxLimit,
      treeCalculation,
      search,
    } = formAcc;
    const isNew = id.toString().indexOf('new-') === 0;
    if (action === 'delete') return null;
    return (
      <Stack key={id} styleName="fa__section" size={Stack.SIZE.none}>
        <Stack horizontal fullWidth alignItems="center">
          <SelectAccountName
            id={`${id}_nameId`}
            bordered={true}
            type="modal"
            size="large"
            label={t(mes.account)}
            placeholder={t(mes.enterAccName)}
            value={{ id: nameId, name: accountName }}
            isDisabled={!isNew}
            exclude={getExcludedAccountNames(currencyId)}
            onChange={(value) => {
              handleChangeItem({
                id,
                param: {
                  nameId: value.id,
                  accountName: value.title || value.name,
                },
                callback: (acc) => handleSaveAccPair(acc),
              });
            }}
          />
          <SelectAccountCurrency
            id={`${id}_currencyId`}
            label={t(mes.currency)}
            placeholder={t(mes.enterAccCurrency)}
            bordered={true}
            type="modal"
            size="large"
            value={{ id: currencyId, name: currencyName }}
            isDisabled={!isNew}
            exclude={getExcludedCurrencies(nameId)}
            onChange={(value) => {
              handleChangeItem({
                id,
                param: {
                  currencyId: value.id,
                  currencyName: value.title || value.name,
                },
                callback: (acc) => handleSaveAccPair(acc),
              });
            }}
          />
          {renderDeleteButton(t(mes.removeFormAcc), () => handleDeleteItem(id))}
        </Stack>
        {error ? renderNoAccessError() : null}
        <Toggle title={t(mes.advancedOptions)} theme="white" isOpen={false}>
          <AccAdvancedOptions
            values={{ minLimit, maxLimit, treeCalculation, search }}
            errors={{}}
            mode="create"
            disabled={!isNew}
            addPlan={false}
            handleOnChange={(paramValue) => {
              handleChangeItem({
                id,
                param: { [paramValue.id]: paramValue.value },
              });
            }}
          />
        </Toggle>
      </Stack>
    );
  };

  return (
    <div styleName="fa">
      <Banner
        styleName="fa__disclaimer"
        size="medium"
        value={t(mes.formAccDisclaimer)}
      />
      <div styleName="fa__wrap">
        <div styleName="fa__list">
          <Space styleName="fa__list__numeric" bottom size={Space.SIZE.xxsmall}>
            <Button
              styleName="fa__list__btn"
              type="text"
              fontWeight="normal"
              icon="add"
              label={t(mes.addFormAcc)}
              onClick={handleAddItem}
            />
            {formAccounts.map((acc) => renderAccountRow(acc))}
          </Space>
          <Toggle
            styleName="fa__list__formFields"
            title={t(mes.fields)}
            theme="white"
            isOpen={false}
          >
            <Space size={Space.SIZE.small}>
              <Stack size={Stack.SIZE.small}>
                {formFields.map((field) => (
                  <Stack.H
                    key={field.id}
                    size={Stack.SIZE.xsmall}
                    alignItems="center"
                  >
                    {renderDeleteButton(t(mes.removeFormAcc), () => {
                      // if only one form field is left, remove last section
                      if (formFields.length > 1) {
                        handleDeleteField(field);
                      } else {
                        handleDeleteSection(field.sectionIndex);
                      }
                    })}
                    <Label value={field.title} />
                  </Stack.H>
                ))}
              </Stack>
            </Space>
          </Toggle>
        </div>
      </div>
    </div>
  );
}

FormAccounts.propTypes = {
  formAccounts: PropTypes.array,
  formFields: PropTypes.array,
  handleDeleteField: PropTypes.func,
  handleChangeAccounts: PropTypes.func,
};

export default FormAccounts;
