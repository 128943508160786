import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Label,
  Modal,
  ModalContent,
  ProgressBar,
  Tab,
  TabItem,
  Chip,
  TextField,
  Utils,
  Tooltip,
  Icon,
} from 'mw-style-react';
import { useSearchList, useScroll } from 'hooks';
import useIntl from 'useIntl';
import { ACTION_ICONS, GET_GROUP, GET_GROUP_MEMBERS } from 'constants'; // NOSONAR
import EmptyList from '@control-front-end/common/components/EmptyList';
import cn from 'classnames';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions'; // NOSONAR
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import { checkUserPermissions } from '../../../../selectors';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './UsersGroupDetails.scss'; // NOSONAR

const LIST_STEPS = 15;
const TABS_NAMES = { users: 'users', apis: 'apis' };

/**
 * Модальное окно состава группы и статистики реакций в разрезе группы
 * @param data
 * @param onClose
 * @param visibility
 * @returns {JSX.Element}
 * @constructor
 */
function UsersGroupDetails({ data, onClose, visibility }) {
  const { saId, name, privs = {}, access = [], reactionsStats = {} } = data;
  const t = useIntl();
  const dispatch = useDispatch();
  const listRef = useRef();
  const [activeTab, setActiveTab] = useState(TABS_NAMES.users);
  const [group, setGroup] = useState({});
  const [groupLoading, setGroupLoading] = useState(false);
  const [query, setQuery] = useState('');
  const count = group.userCount ? group.userCount + group.apiCount : 0;
  const elementsInActiveTab =
    activeTab === TABS_NAMES.apis ? group.apiCount : group.userCount;
  const checkPermissions = useSelector(checkUserPermissions);
  const canViewUsers = checkPermissions([PERMISSIONS.USERS_READONLY]);
  const [skipSearch, setSkipSearch] = useState(true);

  const {
    list,
    makeSearch,
    loading: listLoading,
    onScroll,
  } = useSearchList({
    actionType: GET_GROUP_MEMBERS.REQUEST,
    skip: skipSearch,
    searchParams: {
      query: '',
      userType: activeTab,
    },
    settings: { step: LIST_STEPS },
    saId,
  });

  const loading = (listLoading && !list.length) || groupLoading;
  useScroll({ ref: listRef, callback: onScroll });

  /**
   * Получение деталей и состава группы
   */
  const getGroupDetails = () => {
    setGroupLoading(true);
    dispatch({
      type: GET_GROUP.REQUEST,
      payload: { saId },
      callback: (res) => {
        setGroup(res);
        setSkipSearch(false);
        setGroupLoading(false);
      },
      errorCallback: () => {
        setGroup({ id: saId, accessDenied: true });
        setGroupLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!canViewUsers) return;
    getGroupDetails();
  }, []);

  useEffect(() => {
    const modalBg = document.getElementById('groupDetails');
    if (modalBg && visibility) modalBg.style.zIndex = 10000;
  }, [visibility]);

  const onSearch = (value) => {
    setQuery(value);
    makeSearch({ query: value, userType: activeTab });
  };

  const onChangeTab = (value) => {
    setActiveTab(value);
    setQuery('');
    makeSearch({ query: '', userType: value });
  };
  /**
   * Чипы уровней доступа группы
   */
  const renderPrivs = () => {
    const activePrivs = Object.keys(privs).filter((key) => !!privs[key]);
    return activePrivs.length ? (
      <div>
        <Label fontWeight="semibold" value={t(mes.permissions)} />
        <div styleName="l.content__stat__privs">
          {activePrivs.map((priv) => (
            <Chip
              key={priv}
              styleName="l.content__stat__chip"
              size="small"
              type="rectangular"
              fontWeight="normal"
              label={Utils.toUpperLatter(priv)}
              closeIcon={false}
            />
          ))}
        </div>
      </div>
    ) : null;
  };

  /**
   * Чип количества реакций пользователя
   */
  const getRatingChip = (user) => {
    let rating = 0;
    if (reactionsStats[user.id]) {
      const userStats = reactionsStats[user.id] || {};
      Object.keys(userStats).forEach((i) => {
        rating += userStats[i];
      });
    }
    return (
      <Tooltip topLevel value={t(mes.userRate)}>
        <Chip
          styleName="l.content__list__row__chip rating"
          label={rating || '0'}
          icon="diagram"
          closeIcon={false}
          type="rectangular"
          fontWeight="normal"
          size="small"
        />
      </Tooltip>
    );
  };

  /**
   * Чип sign/done
   */
  const getActionChip = (user, accessLevel) => {
    if (!privs[accessLevel]) return null;
    const userReactions = reactionsStats[user.id] || {};
    const rejected = userReactions.reject;
    let actionIcon = ACTION_ICONS[accessLevel];
    if (accessLevel === 'sign') {
      const signed = userReactions.sign;
      if (!signed && rejected) actionIcon = ACTION_ICONS.reject;
      return (
        <Chip
          styleName={cn('l.content__list__row__chip sign', {
            rejected: !signed && rejected,
            completed: signed,
          })}
          icon={actionIcon}
          closeIcon={false}
          type="rectangular"
          fontWeight="normal"
          size="small"
        />
      );
    }
    const done = userReactions.done;
    if (!done && rejected) actionIcon = ACTION_ICONS.reject;
    return (
      <Chip
        styleName={cn('l.content__list__row__chip done', {
          rejected: !done && rejected,
          completed: done,
        })}
        icon={actionIcon}
        closeIcon={false}
        type="rectangular"
        fontWeight="normal"
        size="small"
      />
    );
  };

  /**
   * Инфо-тултип о персональных правах доступа
   */
  const getCustomAccessInfo = (user) => {
    const userCustomAccess = access.find((u) => u.userId === user.id);
    if (!userCustomAccess) return;
    const customPrivs = userCustomAccess.privs;
    const diff = Object.keys(customPrivs)
      .filter((level) => !!customPrivs[level] && !privs[level])
      .map((i) => Utils.toUpperLatter(i))
      .join(', ');

    return diff.length ? (
      <Tooltip topLevel value={t(mes.userCustomPrivs, { privs: diff })}>
        <Icon styleName="l.content__info" type="info" />
      </Tooltip>
    ) : null;
  };

  /**
   * Список api|users группы
   */
  const renderList = () => {
    if (!loading && !list.length)
      return (
        <EmptyList size="small" objType="user" isSearch={!!query.length} />
      );

    return list.map((user) => (
      <div key={`${user.id}`} styleName="l.content__list__row">
        <div styleName="l.content__list__row__info">
          <div styleName="l.content__list__row__info__avatar__box">
            <UserAvatar
              styleName="l.content__list__row__info__avatar"
              src={user.avatar}
              label={user.nick || user.value}
              userType={user.type}
            />
          </div>
          <Label
            styleName="l.content__list__row__info__name"
            value={user.nick || user.value}
          />
        </div>
        {getCustomAccessInfo(user)}
        {getActionChip(user, 'sign')}
        {getActionChip(user, 'execute')}
        {getRatingChip(user)}
      </div>
    ));
  };

  const renderGroupStats = () => {
    if (!count) return null;
    const usersIds = new Set([...list].map((i) => i.id));
    const signedCount = Object.keys(reactionsStats).filter(
      (userId) =>
        privs.sign && usersIds.has(+userId) && reactionsStats[+userId].sign
    ).length;
    const doneCount = Object.keys(reactionsStats).filter(
      (userId) =>
        privs.done && usersIds.has(+userId) && reactionsStats[+userId].done
    ).length;
    return (
      <>
        {privs.sign ? (
          <div>
            <Label fontWeight="semibold" value={t(mes.signedStat)} />
            <Chip
              styleName="l.content__stat__chip"
              label={`${signedCount} / ${count}`}
              closeIcon={false}
              type="rectangular"
              fontWeight="normal"
              size="small"
            />
          </div>
        ) : null}
        {privs.execute ? (
          <div>
            <Label fontWeight="semibold" value={t(mes.doneStat)} />
            <Chip
              styleName="l.content__stat__chip"
              label={`${doneCount} / ${count}`}
              closeIcon={false}
              type="rectangular"
              fontWeight="normal"
              size="small"
            />
          </div>
        ) : null}
      </>
    );
  };

  const noAccessGroup = () => (
    <div styleName="l.content__accessDenied">
      <Icon size="xlarge" type="no_eye" />
      <Label fontSize="large" value={t(mes.noGroupAccess)} />
    </div>
  );

  const renderGroupMembers = () => (
    <>
      <Tab
        styleName="l.content__tabs"
        type="auto"
        underline={true}
        value={activeTab}
        onChange={({ value }) => onChangeTab(value)}
      >
        <TabItem
          label={`${t(mes.users)} (${group.userCount || 0})`}
          value={TABS_NAMES.users}
        />
        <TabItem
          label={`${t(mes.apiKeys)} (${group.apiCount || 0})`}
          value={TABS_NAMES.apis}
        />
      </Tab>
      <TextField
        styleName="m.textfield"
        leftIcon="search"
        bordered={true}
        value={query}
        rightIcon={query.length ? 'close' : ''}
        placeholder={t(mes.searchByName)}
        onClickRightIcon={() => onSearch('')}
        onChange={({ value }) => onSearch(value)}
        visibility={elementsInActiveTab ? 'visible' : 'hidden'}
      />
      <div styleName="l.content__list" ref={listRef}>
        {loading ? (
          <div styleName="l.loader">
            <ProgressBar type="circle" size="large" />
          </div>
        ) : (
          renderList()
        )}
      </div>
    </>
  );

  return (
    <Modal
      id="groupDetails"
      styleName="m.modal l.modal"
      size="medium"
      onClose={onClose}
      label={group.accessDenied ? name : `${group.nick || name} (${count})`}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content l.content">
        <div styleName="l.content__stat">
          {renderPrivs()}
          {renderGroupStats()}
        </div>
        {!canViewUsers || group.accessDenied
          ? noAccessGroup()
          : renderGroupMembers()}
      </ModalContent>
    </Modal>
  );
}

UsersGroupDetails.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
};

export default UsersGroupDetails;
