import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { STORAGE_KEY } from '@control-front-end/common/constants';
import { MIN_PANEL_WIDTH } from '@control-front-end/common/constants/graphActors';
import './ResizablePanel.scss';

function ResizablePanel({ onChange, children, storageKeyName }) {
  const resizer = useRef();
  const [isResizing, setIsResizing] = useState(false);
  const panelRef = useRef();
  const { defaultWidth: defaultPanelWidth, maxWidth: maxPanelWidth } =
    AppUtils.getDefaultPanelParams(storageKeyName);
  const [width, setWidth] = useState(
    () => Utils.fromStorage(storageKeyName) || defaultPanelWidth
  );

  /**
   * Handler for changing the size of the panel
   */
  const handleResizePanel = (e) => {
    const rect = panelRef.current.getBoundingClientRect();
    const width = rect.width + rect.left - e.clientX;
    if (width < MIN_PANEL_WIDTH || width > maxPanelWidth) return;
    setWidth(width);

    Utils.toStorage(storageKeyName, width);
    onChange?.(width);
  };

  const stopResize = () => {
    setIsResizing(false);
    document.removeEventListener('mousemove', handleResizePanel, false);
    document.removeEventListener('mouseup', stopResize, false);
  };

  useEffect(() => {
    const initResize = () => {
      setIsResizing(true);
      document.addEventListener('mousemove', handleResizePanel, false);
      document.addEventListener('mouseup', stopResize, false);
    };

    const resizerElement = resizer.current;

    resizerElement.addEventListener('mousedown', initResize, false);
    return () => {
      resizerElement?.removeEventListener('mousedown', initResize, false);
    };
  }, []);

  return (
    <div ref={panelRef} style={{ width: `${width}px` }} styleName="wrap">
      <div styleName={cn('panel', { resizing: isResizing })}>
        <div ref={resizer} styleName="panel__resizer">
          <Icon size="small" type="more" />
        </div>
        <div styleName="panel__content">{children}</div>
      </div>
    </div>
  );
}

ResizablePanel.defaultProps = {
  storageKeyName: STORAGE_KEY.panelWidth.default,
};

ResizablePanel.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node,
  storageKeyName: PropTypes.string,
};

export default ResizablePanel;
