import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Tooltip } from 'mw-style-react';

import { useIntl } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import {
  SEARCH_ACCOUNT_CURRENCY,
  SEARCH_ACCOUNT_NAME,
} from '@control-front-end/common/constants/actorAccounts';
import SelectAccountName from '@control-front-end/common/components/SelectAccountName';
import SelectAccountCurrency from '@control-front-end/common/components/SelectAccountCurrency';

import './AccountsFilter.scss';
import mes from './intl';

/**
 * Список пар счетов, по которым фильтровать транзакции
 * @param accounts
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
function AccountsFilter({ accounts, onChange }) {
  const t = useIntl();
  const dispatch = useDispatch();

  const makeNewItem = () => ({
    id: `new-${AppUtils.createRid()}`,
    action: 'create',
    nameId: null,
    currencyId: null,
  });

  /**
   * Добавление новой строки наим.счета + валюта
   */
  const handleAddItem = (item) => {
    const newAccounts = accounts.slice();
    newAccounts.push(item);
    onChange(newAccounts);
  };

  /**
   * Добавить дефолтную пару (Default+USD) в список
   */
  const addDefaultPair = () => {
    dispatch({
      type: SEARCH_ACCOUNT_NAME.REQUEST,
      payload: { query: 'price' },
      callback: (accNamesRes) => {
        const [defaultAccName] = accNamesRes;
        dispatch({
          type: SEARCH_ACCOUNT_CURRENCY.REQUEST,
          payload: { query: 'points' },
          callback: (currenciesRes) => {
            const [defaultCurrency] = currenciesRes;
            handleAddItem({
              id: `new-${AppUtils.createRid()}`,
              action: 'create',
              currencyId: defaultCurrency.id,
              nameId: defaultAccName.id,
              accountName: defaultAccName.name,
              currencyName: defaultCurrency.name,
            });
          },
        });
      },
    });
  };

  useEffect(() => {
    if (!accounts.length) addDefaultPair();
  }, [accounts.length]);

  /**
   * Редактирование строки наим.счета + валюта
   */
  const handleChangeItem = ({ id, param }) => {
    const newAccounts = accounts.slice();
    const itemIndex = newAccounts.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedItem = { ...newAccounts[itemIndex], ...param };
      newAccounts.splice(itemIndex, 1, updatedItem);
      onChange(newAccounts);
    }
  };

  /**
   * Удаление строки наим.счета + валюта
   */
  const handleDeleteItem = (id) => {
    const newAccounts = accounts.slice();
    const itemIndex = newAccounts.findIndex((item) => item.id === id);
    if (itemIndex === -1) return;
    const deletedItem = newAccounts[itemIndex];
    const isNew = id.toString().indexOf('new-') === 0;
    if (isNew) {
      newAccounts.splice(itemIndex, 1);
    } else {
      deletedItem.action = 'delete';
      newAccounts.splice(itemIndex, 1, deletedItem);
    }
    onChange(newAccounts);
  };

  const getExcludedAccountNames = (currencyId) => {
    return accounts
      .filter(
        (acc) => acc.nameId && acc.currencyId && acc.currencyId === currencyId
      )
      .map((i) => i.nameId);
  };

  const getExcludedCurrencies = (nameId) => {
    return accounts
      .filter((acc) => acc.nameId && acc.currencyId && acc.nameId === nameId)
      .map((i) => i.currencyId.toString());
  };

  const renderAccountRow = (acc) => {
    const { id, action, currencyId, nameId, accountName, currencyName } = acc;
    if (action === 'delete') return null;
    const isSinglePair = accounts.length === 1;
    return (
      <div key={id} styleName="accounts__list__item">
        <SelectAccountName
          id={`${id}_nameId`}
          type="modal"
          bordered={true}
          hideCreateBtn={true}
          label={t(mes.account)}
          value={nameId ? { id: nameId, name: accountName } : null}
          exclude={getExcludedAccountNames(currencyId)}
          onChange={(value) => {
            handleChangeItem({
              id,
              param: {
                nameId: value.id,
                accountName: value.title || value.name,
              },
            });
          }}
        />
        <SelectAccountCurrency
          id={`${id}_currencyId`}
          type="modal"
          bordered={true}
          hideCreateBtn={true}
          label={t(mes.currency)}
          nameId={nameId}
          value={currencyId ? { id: currencyId, name: currencyName } : null}
          exclude={getExcludedCurrencies(nameId)}
          onChange={(value) => {
            handleChangeItem({
              id,
              param: {
                currencyId: value.id,
                currencyName: value.title || value.name,
              },
            });
          }}
          isDisabled={!nameId}
        />
        <div
          onClick={() => {
            if (isSinglePair) return;
            handleDeleteItem(id);
          }}
        >
          <Tooltip topLevel value={t(mes.remove)}>
            <Icon
              size="small"
              styleName="accounts__list__item__del"
              type="close"
              visibility={isSinglePair ? 'disabled' : 'visible'}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <div styleName="accounts">
      <div styleName="accounts__list">
        {accounts.map((acc) => renderAccountRow(acc))}
        <Button
          styleName="accounts__list__btn"
          size="small"
          type="text"
          fontWeight="normal"
          icon="add"
          label={t(mes.addPair)}
          onClick={() => handleAddItem(makeNewItem())}
        />
      </div>
    </div>
  );
}

AccountsFilter.propTypes = {
  accounts: PropTypes.array,
  onChange: PropTypes.func,
};

export default AccountsFilter;
