import { useRef } from 'react';
import { throttle, keyBy, mapValues } from 'lodash';

import {
  snapGeomCoord,
  keyByPosition,
  isIntersectionsAllowed,
  moveNodes,
  getNodeSnapType,
  NODE_SNAP_TYPE,
  getGraphCursorPosition,
} from '@control-front-end/utils/modules/utilsCellCoords';
import { CUSTOM_EVENTS } from '@control-front-end/common/constants/graph';
import { useDrag } from 'hooks';

const THROTTLE_TIME_FOR_OPTIMAL_DRAG = 50;

const moveNodesThrottled = throttle(moveNodes, THROTTLE_TIME_FOR_OPTIMAL_DRAG);

/**
 * TODO: Unify logic with useGridSnap and remove code duplication
 * @see packages/app/src/components/GraphEngine/useGridSnap.js
 *
 * Custom hook for handling drag and snapping logic of epanded nodes, dragged via node-widget
 * @param {Object} props
 * @param {Cytoscape} props.cy
 * @param {Cytoscape.Node} props.node
 * @param {Object} props.ref
 * @param {function} props.onDrag
 * @param {function} props.onUp
 * @param {function} props.onDown
 * @param {boolean} props.isDraggable
 */
const useGridExpNodesSnap = ({
  cy,
  node,
  ref,
  onDrag,
  onUp,
  onDown,
  isDraggable = true,
}) => {
  const stateRef = useRef({
    cursorPosition: null,
    dragStart: null,
    nodesStartPositions: {},
    occupiedPositions: {},
    nodesToDrag: [],
    dragged: false,
  });

  const onDragFree = (e) => {
    const {
      cursorPosition,
      dragStart,
      occupiedPositions,
      nodesStartPositions,
      nodesToDrag,
      dragged,
    } = stateRef.current;

    // if position not shifted (normalized click) - just unlock nodes and return
    if (!stateRef.current.cursorPosition) {
      nodesToDrag.forEach((n) => n.unlock());
      return;
    }

    const positionShift = {
      x: cursorPosition.x - dragStart.x,
      y: cursorPosition.y - dragStart.y,
    };

    if (!dragged && positionShift.x === 0 && positionShift.y === 0) {
      nodesToDrag.forEach((n) => n.unlock());
      return;
    }

    moveNodes({
      cy,
      nodes: [node],
      allowReset: true,
      occupiedPositions,
      positionShift,
      nodesStartPositions,
      callback: () => {
        onDrag?.({ cursorPosition });
        stateRef.current.dragged = true;
      },
    });

    nodesToDrag.forEach((node) => {
      node.unlock();
      node.trigger('free');
      node.trigger('dragfree');
    });
    cy.trigger(CUSTOM_EVENTS.dragfree_bulk, cy.collection(nodesToDrag));

    stateRef.current.dragged = false;
    stateRef.current.cursorPosition = null;
    stateRef.current.nodesToDrag = [];

    onUp?.(e);
  };

  const onTapDrag = (e) => {
    const { dragStart } = stateRef.current;

    const newCursorPosition = snapGeomCoord(
      getGraphCursorPosition(cy, { originalEvent: e })
    );
    if (
      !dragStart ||
      (newCursorPosition.x === stateRef.current.cursorPosition?.x &&
        newCursorPosition.y === stateRef.current.cursorPosition?.y)
    ) {
      return;
    }

    stateRef.current.cursorPosition = newCursorPosition;

    const positionShift = {
      x: stateRef.current.cursorPosition.x - dragStart.x,
      y: stateRef.current.cursorPosition.y - dragStart.y,
    };

    if (
      !stateRef.current.dragged &&
      positionShift.x === 0 &&
      positionShift.y === 0
    )
      return;

    moveNodesThrottled({
      cy,
      nodes: [node],
      ...stateRef.current,
      positionShift,
      callback: () => {
        onDrag?.({ cursorPosition: stateRef.current.cursorPosition });
        stateRef.current.dragged = true;
      },
    });
  };

  const onTapStart = (e) => {
    if (!node.grabbable() || node.locked()) return;

    if (!node.selected()) {
      cy.$(':selected').unselect();
      node.select();
      node.grabify();
    }

    stateRef.current.nodesToDrag = cy.$(':selected');

    // Enable free dragging for single, non-snapping node
    if (
      stateRef.current.nodesToDrag.length === 1 &&
      getNodeSnapType(stateRef.current.nodesToDrag[0].data()) ===
        NODE_SNAP_TYPE.none
    ) {
      return;
    }

    stateRef.current.dragged = false;
    stateRef.current.dragStart = snapGeomCoord(
      getGraphCursorPosition(cy, { originalEvent: e })
    );

    // Remember dragged nodes initial positions
    stateRef.current.nodesStartPositions = stateRef.current.nodesToDrag.reduce(
      (acc, node) => {
        acc[node.id()] = { x: node.position('x'), y: node.position('y') };
        node.lock();
        node.trigger('grab');
        return acc;
      },
      {}
    );

    cy.trigger(
      CUSTOM_EVENTS.grab_bulk,
      cy.collection(stateRef.current.nodesToDrag)
    );

    stateRef.current.occupiedPositions = mapValues(
      keyBy(
        cy.nodes(':unselected').filter((node) => !isIntersectionsAllowed(node)),
        (node) => keyByPosition(node.position())
      ),
      () => true
    );

    onDown?.(e);
  };

  const dragData = useDrag(ref, onTapDrag, onDragFree, onTapStart, isDraggable);

  return dragData;
};

export default useGridExpNodesSnap;
