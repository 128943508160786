import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { Utils } from 'mw-style-react';

import api from '@control-front-end/common/sagas/api';
import { REMOVE_LAYER } from '@control-front-end/common/constants/graphLayers';
import {
  ACTORS_ACCESS,
  ADD_ACTOR,
  CREATE_ACTOR,
  CREATE_ACTOR_LIST,
  GET_ACTOR,
  GET_ACTOR_VIEW_DATA,
  GET_ACTOR_TPL,
  GET_ACTORS,
  GET_ACTORS_BALANCE,
  GET_ITEMS_BY_IDS,
  GET_LINKED_ACTORS,
  GET_LINKED_FORMS,
  UPDATE_ACTOR_VIEW,
  REMOVE_ACTOR,
  REMOVE_ACTOR_LIST,
  SEND_ACTORS_DATA,
  UPDATE_ACTOR,
  BULK_UPDATE_ACTORS_FIELD,
  UPDATE_ACTOR_LIST,
  STARRED_ACTOR,
  SEARCH_LINKED_ACTORS,
  SET_ACTORS_CACHE,
  GET_ACTOR_BY_REF,
  REMOVE_ACTOR_FORMS,
  REMOVE_ALL_FORM_ACTORS,
  SET_ACTOR_VIEW_REQ_STATUS,
  COPY_ACTOR,
  VALENCY_GRAPH,
  BULK_UPDATE_ACTORS_SETTINGS,
} from '@control-front-end/common/constants/graphActors';
import takeLeadingBy from '@control-front-end/common/sagas/takeLeadingBy';
import { HIDE_ACTORS_FILTER } from '@control-front-end/common/constants/actorsFilters';
import { manageAccessRules } from '@control-front-end/common/sagas/accessRules';
import { MAKE_SOUND, RequestStatus } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import { HIDE_STREAM } from '@control-front-end/common/constants/streams';
import { SUPPORT_URL_PARAMS } from '@control-front-end/common/constants/urlParams';
import { DEFAULT_NAME } from '../../routes/ActorsGraph/routers/Graph/constants';
import {
  sendReducerMsg,
  makeGraphModels,
  getAllGraphEls,
  getActiveLayer,
  getLayerById,
  getActorByGraphId,
  setActorsBalances,
  groupActorsByTpl,
  excludeAccessDenied,
  updateActorOnGraph,
  extendModelWithAvatars,
  updateLayerActor,
  updateValencyLayerActor,
  makeActorPicture,
  getSnappedNodePosition,
} from './graphHelpers';
import { removeNodesFromGraph } from './graphUtils';
import { updateActorView } from '../actorView';
import { manageLayerElements } from '../../routes/ActorsGraph/sagas/layers/layerElements';
import { removeLayerFromStore } from '../../routes/ActorsGraph/sagas/layers/layerManage';
import { updateGraphFolder } from './graphFolders';

/**
 * Обновить элемент списка
 */
function* updateActorInList({ actorId, field, value }) {
  const { list } = yield select((state) => state.actorsList);
  const copyList = list.slice();
  const index = copyList.findIndex((i) => i.id === actorId);
  if (index !== -1) {
    const copyActor = { ...copyList[index] };
    copyActor[field] = value;
    copyList.splice(index, 1, copyActor);
    return copyList;
  }
  return copyList;
}

/**
 * Удалить акторы из списка
 */
export function* removeActorsFromList(actors) {
  actors = Array.isArray(actors) ? actors : [actors];
  const { list, offset, total, activeActor } = yield select(
    (state) => state.actorsList
  );
  const newList = list.filter((i) => !actors.includes(i.id));
  const findActive = newList.find((i) => i.id === activeActor);
  const newActiveActor = findActive ? activeActor : null;
  const removedMatches = list.filter((i) => actors.includes(i.id));
  const removedCount = removedMatches.length;
  yield put({
    type: REMOVE_ACTOR_LIST.SUCCESS,
    payload: {
      list: newList,
      total: total - removedCount,
      offset: offset - actors.length,
      endList: newList.length === total - removedCount,
      activeActor: newActiveActor,
    },
  });
}

/**
 * Получить шаблон актора
 */
function* getActorTpl({ payload, callback }) {
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/forms/${payload}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_ACTOR_TPL.SUCCESS,
    payload: data.data,
  });
  if (callback) callback(data.data);
}

/**
 * Обработка клика по кнопке на форме актора
 */
function* sendActorsData({ payload, callback }) {
  const { actorId, buttonId, data: body } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/actors/send/${actorId}/${buttonId}`,
    body,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: SEND_ACTORS_DATA.SUCCESS });
  if (callback) callback(data.data);
}

/**
 * Получить баланс акторов
 */
function* getActorsBalance({ payload, callback }) {
  const {
    layerId,
    currencyId,
    nameId,
    actorIds,
    accountType = 'fact',
    from,
    to,
    currencyParams,
  } = payload;
  const accounts = yield select((state) => state.accounts);
  let defaultCurParams;
  if (!currencyParams) {
    const factAccounts = yield select((state) => state.actorsAccounts.fact);
    const currentAccount =
      factAccounts.find(
        (i) => i.nameId === nameId && i.currencyId === currencyId.toString()
      ) || {};
    const {
      currencyType: type,
      precision,
      symbol,
    } = currentAccount.debit || {};
    defaultCurParams = { type, precision, symbol };
  }
  const accId = accounts.active;
  const layer = yield getLayerById(layerId);
  if (!layer) return;
  const copyNodes = layer.nodes ? structuredClone(layer.nodes) : [];
  const nodeIds =
    actorIds ||
    copyNodes.filter((i) => i.data.type === 'node').map((a) => a.id);
  if (!nodeIds.length) return;
  let balancesChanged = false;
  if (currencyId && nameId) {
    const { result, data } = yield call(api, {
      method: 'post',
      url: `/actors/balance/${accId}`,
      queryParams: {
        currencyId,
        nameId,
        accountType,
        from,
        to,
      },
      body: nodeIds,
    });
    if (result !== RequestStatus.SUCCESS) return;

    const layer = yield getLayerById(layerId);
    const copyNodes = layer?.nodes ? structuredClone(layer.nodes) : [];
    setActorsBalances({
      balances: data.data,
      nodes: copyNodes,
      currencyParams: currencyParams || defaultCurParams,
    });
    balancesChanged = true;
  } else {
    copyNodes.forEach((node) => {
      if (node.data.type === 'node' && node.data.balance) {
        balancesChanged = true;
        delete node.data.balanceVector;
        node.data.balance = null;
        node.status = 'updated';
      }
    });
  }
  yield sendReducerMsg({
    layerId,
    type: GET_ACTORS_BALANCE.SUCCESS,
    payload: balancesChanged ? { nodes: copyNodes } : {},
  });
  if (callback) callback();
}

/**
 * Изменить права доступа на акторы
 */
function* manageActorsAccess({ payload }) {
  const { rules } = payload;
  const { list } = yield select((state) => state.actorsList);
  const copyList = structuredClone(list);
  for (const obj of rules) {
    const findItem = copyList.find((i) => i.id === obj.id);
    if (findItem) findItem.access = obj.access;
  }
  yield put({
    type: ACTORS_ACCESS.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Получить актор и связанные с ним акторы
 */
export function* getActors({ payload, callback, typeLayer }) {
  const { actorId, edgeType } = payload;
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeM = edgeTypes.find((i) => i.name === edgeType) || {};
  const edgeTypeId = payload.edgeTypeId || edgeTypeM.id;
  const reqAId = actorId.split('_')[0];
  let url;
  switch (typeLayer) {
    case 'trees':
      url = `/graph/tree/${reqAId}`;
      break;
    case 'actors':
      url = `/graph/linked_actors/${reqAId}`;
      break;
    default:
      url = `/graph/linked_actors/${reqAId}`;
  }
  const { result, data } = yield call(api, {
    method: 'get',
    url,
    queryParams: { edgeTypeId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  let availableNodes;
  let availableEdges;
  // только для дерева счетов оставляем узлы без доступа
  const isTreeLayer = typeLayer === 'trees';
  if (!isTreeLayer) {
    const graphEls = excludeAccessDenied(data.data);
    availableNodes = graphEls.nodes;
    availableEdges = graphEls.edges;
  }
  const { nodes, edges } = yield call(makeGraphModels, {
    nodes: isTreeLayer ? data.data.nodes : availableNodes,
    edges: isTreeLayer ? data.data.edges : availableEdges,
    isTree: typeLayer !== 'graph',
  });
  let groupEdges = edges;
  let hiddenEdges = [];
  if (typeLayer === 'actors') {
    const reqActor = nodes.find((node) => node.id === reqAId);
    if (reqActor) {
      const isActorLayerIFrame =
        document.location.pathname.includes('/single/actors/');
      reqActor.data.layerSettings.profile = !isActorLayerIFrame;
    }
    const groupRes = yield groupActorsByTpl({
      rootActorId: reqAId,
      nodes,
      edges,
      edgeTypeId,
      typeLayer,
    });
    groupEdges = groupRes.groupEdges;
    hiddenEdges = groupRes.hiddenEdges;
  }
  const prevNodes = typeLayer ? [] : yield call(getAllGraphEls, 'nodes');
  const prevEdges = typeLayer ? [] : yield call(getAllGraphEls, 'edges');
  const newNodes = AppUtils.uniqueObjArray(prevNodes.concat(nodes), 'id');
  const newEdges = AppUtils.uniqueObjArray(prevEdges.concat(groupEdges), 'id');
  if (!isTreeLayer) {
    const actor = newNodes.find((i) => i.data.actorId === actorId);
    actor.data.linkedActorsLoaded = true;
  }
  const payloadRes = {
    key: AppUtils.createRid(),
    nodes: newNodes,
    edges: newEdges,
    hiddenEdges,
    rootTreeId: data.data.rootTreeId,
    treeEdgeTypeInfo: data.data.treeEdgeTypeInfo,
    activeElement: actorId,
    activeType: 'node',
  };
  if (!typeLayer) {
    yield sendReducerMsg({
      type: GET_ACTORS.SUCCESS,
      payload: payloadRes,
    });
  }
  callback?.(payloadRes);
  return payloadRes;
}

export function* getLinkedForms({ payload, callback }) {
  const { actorId, edgeType, exceptFormId, withStats, limit, offset } = payload;
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeM = edgeTypes.find((i) => i.name === edgeType) || {};
  const edgeTypeId = payload.edgeTypeId || edgeTypeM.id;

  const { result, data } = yield call(api, {
    method: 'get',
    url: `/graph/linked_forms/${actorId}`,
    queryParams: { edgeTypeId, exceptFormId, withStats, limit, offset },
  });
  if (result !== RequestStatus.SUCCESS) return;

  yield sendReducerMsg({
    type: GET_LINKED_FORMS.SUCCESS,
    payload: data.data,
  });
  callback?.(data.data);
}

/**
 * Изменить права доступа на слой
 */
function* cacheActorData({ id, data }) {
  yield put({
    type: SET_ACTORS_CACHE,
    payload: { id, data },
  });
}

/**
 * Получить актор
 */
export function* getActor({ payload, callback, errorCallback }) {
  const {
    id,
    attachments = false,
    actorView = false,
    actorViewQuiet = false,
    cache = false,
    reactionsStats,
    view,
  } = payload;
  if (actorView)
    yield put({ type: SET_ACTOR_VIEW_REQ_STATUS, payload: 'inProgress' });
  const settings = yield select((state) => state.settings);

  const supportUrlParams = Utils.pickBy(
    Utils.getQueryParam(document.location.search),
    (v, key) => SUPPORT_URL_PARAMS[key]
  );
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors/${id}`,
    queryParams: {
      attachments,
      reactionsStats,
      view,
      /**
       * Needed for correct counters work on the streams tabs and required only in split mode
       * @warning avoid of using it in other case because of its affect to back-end performance
       */
      streams: Boolean(settings.plainMode),
      /**
       * "webhooks" flag is required for support team when thay work in split mode with any custom parms
       * It will trigger some additional logic on the backend
       */
      webhooks:
        settings.plainMode && Boolean(Object.keys(supportUrlParams).length),
    },
    handleErrCodes: errorCallback ? [403] : [],
  });
  if (actorView)
    yield put({ type: SET_ACTOR_VIEW_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) {
    if (!data) return;
    if (data.statusCode === 403 && errorCallback) errorCallback(data);
    if (cache) yield cacheActorData({ id, data: { id, accessDenied: true } });
    return { error: true, title: 'Access Denied' };
  }
  yield call(extendModelWithAvatars, data.data);
  if (data.data.attachments) {
    for (const i of data.data.attachments) {
      i.filePath = yield call(AppUtils.makeAppUrl, `/download/${i.fileName}`);
    }
  }
  data.data.actorId = data.data.id;
  if (actorView || actorViewQuiet) {
    yield put({
      type: UPDATE_ACTOR_VIEW.SUCCESS,
      payload: data.data,
    });
  }
  const { list } = yield select((state) => state.actorsList);
  const copyList = list.slice();
  const index = copyList.findIndex((i) => i.id === payload);
  if (index !== -1) {
    const newActor = data.data;
    copyList.splice(index, 1, newActor);
    yield put({
      type: UPDATE_ACTOR_LIST.SUCCESS,
      payload: {
        list: copyList,
      },
    });
  }
  if (cache) yield cacheActorData({ id, data: data.data });
  if (callback) callback(data.data);
  return data.data;
}

export function* getActorViewData({ payload, callback, errorCallback }) {
  const { id } = payload;

  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors/view_data/${id}`,
    handleErrCodes: errorCallback ? [403] : [],
  });

  if (result !== RequestStatus.SUCCESS) {
    if (!data) return;
    if (data.statusCode === 403 && errorCallback) errorCallback();
    return { error: true, title: 'Access Denied' };
  }

  if (callback) callback(data.data);
  return data.data;
}

/**
 * Получить актор по ref
 */
export function* getActorByRef({ payload, callback, errorCallback }) {
  const { formId, ref } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors/ref/${formId}/${ref}`,
    handleErrCodes: errorCallback ? [403, 404] : [],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (data.statusCode !== 404 && errorCallback)
      errorCallback({
        error: true,
        title: 'Access Denied',
        accessDenied: true,
      });
    return;
  }
  yield call(extendModelWithAvatars, data.data);
  if (callback) callback(data.data);
}

/**
 * Получить связанные акторы
 */
export function* getLinkedActors({ payload, callback }) {
  const {
    id,
    formId,
    exceptFormId,
    starred,
    limit,
    offset,
    actorView = false,
    linkType = 'linked',
  } = payload;
  const edgeType = 'hierarchy';
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === edgeType);
  const queryParams = {
    edgeTypeId: edgeTypeH.id,
    formId,
    exceptFormId,
    starred,
    limit,
    offset,
  };
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/graph/${linkType}/${id}`,
    queryParams,
  });
  if (result !== RequestStatus.SUCCESS) return { list: [], total: 0 };
  for (const obj of data.data.list) {
    yield call(extendModelWithAvatars, obj);
  }
  if (actorView) {
    yield call(updateActorView, {
      payload: { actorData: { id, actors: data.data.list } },
    });
  }
  yield put({
    type: GET_LINKED_ACTORS.SUCCESS,
    payload: { list: data.data },
  });
  if (callback) callback(data.data);
  return data.data;
}

function* getValencyActors({ payload }) {
  const { layerId } = payload;
  const { nodes, edges } = yield getActors({
    payload: { actorId: layerId, edgeType: 'hierarchy' },
    typeLayer: 'actor',
  });
  yield put({
    type: VALENCY_GRAPH.GET.SUCCESS,
    payload: { list: structuredClone([...nodes, ...edges]) },
  });
}

/**
 * Найти связанные акторы
 */
export function* searchLinkedActors({ payload, callback }) {
  const { id, formId, query } = payload;
  const edgeType = 'hierarchy';
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === edgeType);
  const queryParams = { edgeTypeId: edgeTypeH.id, formId };
  const searchQ = AppUtils.safeSearchQuery(query);
  if (!searchQ.length) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/graph/search/linked/${id}/${searchQ}`,
    queryParams,
  });
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data.list) {
    yield call(extendModelWithAvatars, obj);
  }
  yield put({
    type: SEARCH_LINKED_ACTORS.SUCCESS,
    payload: { list: data.data },
  });
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Получить список акторов, счетов, валют по id
 */
export function* getItemsByIds({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { itemsToSearch, fullModel = false } = payload;
  const reqs = [];
  Object.keys(itemsToSearch).forEach((key) => {
    if (itemsToSearch[key].length === 0) return;
    let url;
    let body;
    switch (key) {
      case 'accounts':
        url = `/account_names/list/${accId}`;
        body = { names: itemsToSearch[key] };
        break;
      case 'actors':
      case 'actorFilter':
        url = `/actors/list/${accId}`;
        body = { actors: itemsToSearch[key] };
        break;
      case 'currencies':
        url = `/currencies/list/${accId}`;
        body = { currencies: itemsToSearch[key] };
        break;
      case 'workspaceMembers':
        url = `/users/list/${accId}`;
        body = { users: itemsToSearch[key] };
        break;
      default:
        return;
    }
    reqs.push(
      call(api, { method: 'post', url, body, handleErrCodes: [403, 404] })
    );
  });
  const resp = yield all(reqs);
  const error = resp.find(
    (el) => el.data.statusCode === 403 || el.data.statusCode === 404
  );
  if (error) {
    if (callback) callback([{ error, title: error.data.message }]);
    return;
  }
  const data = resp.reduce((prev, cur) => prev.concat(cur.data.data), []);
  if (fullModel) {
    for (const obj of data) {
      obj.pictureUrl = yield makeActorPicture(obj);
    }
    if (callback) callback(data);
  } else {
    const foundedItems = data.map((item) => ({
      id: item.id,
      title: item.title || item.name || item.nick,
    }));
    if (callback) callback(foundedItems);
  }
  return resp;
}

/**
 * Создать копии актора (асинхронная задача)
 */
function* copyActor({ payload, callback }) {
  const { actorId, count } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/actors/copy_actor/${actorId}`,
    body: { count },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data);
}

/**
 * Update actor
 */
export function* updateActor({ payload, callback, formActions }) {
  const {
    title,
    description,
    ref,
    color,
    picture,
    pictureObject,
    id,
    actorId,
    formId,
    formData,
    appId,
    appSettings,
    cardActorId,
    status,
    viewData,
  } = payload;
  const newActorData = {
    title,
    description,
    ref,
    color,
    picture,
    pictureObject,
    appId,
    appSettings,
    cardActorId,
    status,
    viewData,
  };
  let nModel = yield getActorByGraphId(id, 'nodes');
  if (!nModel) nModel = { actorId: id || actorId };
  if (formData) newActorData.data = formData;
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/actors/actor/${formId}/${nModel.actorId || nModel.id}`,
    body: newActorData,
  });
  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  const newActor = structuredClone(data.data);
  yield call(extendModelWithAvatars, newActor);
  yield call(updateActorView, { payload: { actorData: newActor } });
  yield call(updateLayerActor, { layerId: id, data: newActor });
  yield call(updateValencyLayerActor, newActor);
  yield call(updateGraphFolder, { graphFolderId: id, data: newActor });
  const { list } = yield select((state) => state.actorsList);
  const copyList = structuredClone(list);
  const index = copyList.findIndex((i) => i.id === id);
  newActor.laId = nModel.laId;
  newActor.actorId = nModel.actorId;
  newActor.position = nModel.position;
  newActor.lastReaction = nModel.lastReaction;
  newActor.reactionsCount = nModel.reactionsCount;
  newActor.reactionsStats = nModel.reactionsStats;
  newActor.layerSettings = nModel.layerSettings;
  newActor.actorNumber = nModel.actorNumber;
  const { nodes } = yield call(updateActorOnGraph, { actorModel: newActor });
  yield sendReducerMsg({
    type: UPDATE_ACTOR.SUCCESS,
    payload: { nodes, editableElement: null },
  });
  if (callback) callback(newActor);
  if (index === -1) return;
  copyList.splice(index, 1, newActor);
  yield put({
    type: UPDATE_ACTOR_LIST.SUCCESS,
    payload: {
      list: copyList,
    },
  });
}

/**
 * Revise default naming for graph and layer that haven't been set by user
 */
export function* reviseDefaultNamings({ layer, title }) {
  const graphFolders = yield select((state) => state.graphFolders.list);

  const layersGraphFolder =
    layer && graphFolders
      ? graphFolders.find((i) => i.id === layer.graphFolderId)
      : null;

  const systemFormsGraphs = yield select((state) => state.systemForms.graphs) ||
    {};

  if (layer.title.startsWith(DEFAULT_NAME.LAYER)) {
    yield updateActor({
      payload: {
        id: layer.id,
        formId: layer.formId,
        title,
      },
    });
  }

  if (layersGraphFolder?.title === DEFAULT_NAME.GRAPH) {
    yield updateActor({
      payload: {
        id: layer.graphFolderId,
        formId: systemFormsGraphs.id,
        title,
      },
    });
  }
}

/**
 * Create actor
 */
export function* createActor({ payload, callback, formActions }) {
  const {
    title,
    description,
    ref,
    color,
    picture,
    pictureObject,
    areaPicture,
    formData,
    position,
    cardActorId,
    status,
    appId,
    appSettings,
    access,
    extraData = {},
    manageLayer = true,
    reduxEvent = true,
    viewData,
  } = payload;
  let { formId } = payload;
  if (!formId) {
    const defaultActorTpl = yield select((state) => state.defaultActorTpl);
    formId = defaultActorTpl.id;
  }
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/actors/actor/${formId}`,
    body: {
      title,
      description,
      [ref ? 'ref' : null]: ref,
      color,
      picture,
      pictureObject,
      cardActorId,
      status,
      appId,
      appSettings,
      data: formData || {},
      viewData,
    },
  });
  if (result !== RequestStatus.SUCCESS) {
    formActions?.setSubmitting(false);
    return;
  }

  const positionData = yield getSnappedNodePosition({ ...data, position });

  const newModel = {
    ...data.data,
    areaPicture,
    ...extraData,
    ...positionData,
  };
  newModel.actorId = data.data.id;
  yield call(extendModelWithAvatars, newModel);
  if (access) {
    yield manageAccessRules({
      payload: {
        body: access,
        objType: 'actor',
        objId: newModel.id,
        recursive: false,
      },
    });
  }

  const activeLayer = yield getActiveLayer();
  if (activeLayer && manageLayer && reduxEvent) {
    const isFirstLayerActor = !activeLayer.nodes?.length;
    const isDefaultTitle = /^Actor\s[a-z0-9]{8}$/.test(newModel.title);
    const { addedNodes } = yield call(manageLayerElements, {
      payload: {
        layerId: activeLayer.id,
        activeElement: newModel.id,
        activeType: 'node',
        editableElement: isDefaultTitle ? newModel.id : null,
        body: [
          {
            action: 'create',
            data: {
              id: newModel.id,
              model: newModel,
              ...positionData,
              areaPicture,
              type: 'node',
            },
          },
        ],
        withReq: activeLayer.typeLayer === 'layers',
      },
    });
    yield put({ type: MAKE_SOUND, payload: { type: 'addActor' } });
    newModel.laId = addedNodes[0].laId;
    if (newModel.title && isFirstLayerActor) {
      yield reviseDefaultNamings({ layer: activeLayer, title: newModel.title });
    }
  } else if (reduxEvent) {
    const { list, formId: activeFormId } = yield select(
      (state) => state.actorsList
    );
    let newList = list.slice();
    // check if actor wasn't already added to actorsList via websocket event
    // if so, just update its data with newModel
    const newElIndex = list.findIndex((i) => i.id === newModel.id);
    if (newElIndex !== -1) {
      newList.splice(newElIndex, 1, newModel);
    } else if (newModel.formId === activeFormId) {
      newList.unshift(newModel);
    } else {
      newList = null;
    }
    // if actor was already in list, call UPDATE_ACTOR_LIST to prevent total counter increase
    if (newList) {
      yield put({
        type:
          newElIndex !== -1
            ? UPDATE_ACTOR_LIST.SUCCESS
            : CREATE_ACTOR_LIST.SUCCESS,
        payload: {
          list: newList,
          activeActor: newModel.id,
        },
      });
    }
  }
  formActions?.setSubmitting(false);
  yield put({ type: CREATE_ACTOR.SUCCESS });
  if (callback) callback(newModel);
  return newModel;
}

/**
 * Добавить модель актора на граф
 */
export function* addActor({ payload, callback }) {
  const {
    actorModel,
    position,
    areaPicture,
    polygon,
    manageLayer,
    editableElement,
  } = payload;
  const activeLayer = yield getActiveLayer();
  const isFirstLayerActor = !activeLayer.nodes?.length;
  const layerId = payload.layerId || activeLayer.id;
  if (activeLayer) {
    yield put({ type: MAKE_SOUND, payload: { type: 'addActor' } });
  }

  const positionData = yield getSnappedNodePosition({
    data: payload.actorModel,
    position,
    polygon,
  });

  const { nodesMap } = yield call(manageLayerElements, {
    payload: {
      layerId,
      editableElement,
      body: [
        {
          action: 'create',
          data: {
            id: actorModel.id,
            model: {
              ...actorModel,
              polygon,
              areaPicture,
              ...positionData,
            },
            type: 'node',
            areaPicture,
            polygon,
            ...positionData,
          },
        },
      ],
      withReq: manageLayer,
    },
  });
  yield put({ type: ADD_ACTOR.SUCCESS });
  const addedActor = { ...actorModel };
  if (nodesMap && nodesMap[0]) addedActor.laId = nodesMap[0].laId;
  if (addedActor.title && isFirstLayerActor) {
    yield reviseDefaultNamings({ layer: activeLayer, title: addedActor.title });
  }
  if (callback) callback(addedActor);
}

/**
 * Удалить актор
 */
function* removeActor({ payload, callback }) {
  const { id, manageLayer, fromWs } = payload;
  const nodeModel = yield getActorByGraphId(id, 'nodes');
  const activeLayer = yield getActiveLayer();
  const actorId = nodeModel && nodeModel.actorId ? nodeModel.actorId : id;

  if (activeLayer && nodeModel) {
    const withReq =
      !activeLayer.isSystem &&
      !!manageLayer &&
      !nodeModel.isAutoLayerNode &&
      !fromWs &&
      !(manageLayer && activeLayer.typeLayer === 'trees');
    yield call(manageLayerElements, {
      payload: {
        layerId: activeLayer.id,
        activeElement: null,
        activeType: null,
        editableElement: null,
        body: [
          {
            action: 'delete',
            data: {
              id: nodeModel.actorId,
              model: nodeModel,
              type: 'node',
              withDuplicates: !manageLayer,
            },
          },
        ],
        withReq,
      },
    });

    yield removeNodesFromGraph((n) => n.data.actorId === actorId);

    yield put({
      type: VALENCY_GRAPH.REMOVE_NODES.SUCCESS,
      payload: [{ id: actorId }],
    });
  }
  if (!manageLayer && !fromWs) {
    const { result } = yield call(api, {
      method: 'delete',
      url: `/actors/${actorId}`,
    });
    if (result !== RequestStatus.SUCCESS) return;
  }
  yield call(removeLayerFromStore, {
    layerId: actorId,
    type: REMOVE_LAYER.SUCCESS,
  });
  yield call(removeActorsFromList, [actorId]);
  yield put({ type: HIDE_ACTORS_FILTER.REQUEST, payload: { id } });
  yield put({ type: HIDE_STREAM.REQUEST, payload: { id } });
  if (callback) callback({ activeLayer });
}

/**
 * Multiple actors updates in parallel to make only one graph update in store
 * (instead of special batch api request, which will be very heavy)
 */
function* bulkUpdateActorsSettings({ payload, callback }) {
  const { actors } = payload;
  if (!actors.length) return;
  const reqs = actors.map((i) =>
    call(api, {
      method: 'put',
      url: `/graph_layers/actor_settings/${i.id}`,
      body: { polygon: i.polygon },
      handleErrCodes: [404],
    })
  );
  const resp = yield all(reqs);
  const actorModel = [];
  for (const actor of actors) {
    const node = yield getActorByGraphId(actor.id, 'nodes');
    const index = actors.findIndex((i) => i.id === actor.id);
    if (resp[index].result !== RequestStatus.SUCCESS) continue;
    actorModel.push({ ...node, ...actor });
  }
  const { nodes } = yield call(updateActorOnGraph, { actorModel });
  yield sendReducerMsg({
    type: BULK_UPDATE_ACTORS_SETTINGS.SUCCESS,
    payload: { nodes, editableElement: null },
  });
  if (callback) callback();
}

/**
 * Обновить поле у списка акторов
 */
export function* bulkUpdateActorsField({ payload, callback }) {
  const { actors, field, value } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/actors/bulk/${accId}`,
    body: {
      actors,
      field,
      value,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const actorModels = [];
  for (const actorId of actors) {
    const node = yield getActorByGraphId(actorId, 'nodes');
    const actorModel = { ...node };
    actorModel[field] = value;
    actorModels.push(actorModel);
  }
  const { nodes } = yield call(updateActorOnGraph, {
    actorModel: actorModels,
  });
  yield sendReducerMsg({
    type: BULK_UPDATE_ACTORS_FIELD.SUCCESS,
    payload: { nodes, editableElement: null },
  });
  if (callback) callback(data);
}

/**
 * Удалить формы актора
 */
function* removeActorForms({ payload, callback }) {
  const { actorId, forms } = payload;
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/actors/actor_forms/${actorId}`,
    body: { forms },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data);
}

/**
 * Удалить все акторы формы UAT
 */
function* removeAllFormActors({ payload, callback }) {
  const { formId } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: 'delete',
    url: `/actors/${accId}/${formId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: REMOVE_ALL_FORM_ACTORS.SUCCESS });
  if (callback) callback(data);
}

/**
 * Изменить флаг starred
 */
function* starredActor({ payload }) {
  const { actorId, starred } = payload;
  const { result } = yield call(api, {
    method: 'post',
    url: `/actors/starred/${actorId}/${starred}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield call(updateActorView, {
    payload: { actorData: { id: actorId, starred } },
  });
  yield call(updateLayerActor, { layerId: actorId, data: { starred } });
  yield call(updateGraphFolder, { graphFolderId: actorId, data: { starred } });
  const copyList = yield updateActorInList({
    actorId,
    field: 'starred',
    value: starred,
  });
  yield put({
    type: UPDATE_ACTOR_LIST.SUCCESS,
    payload: {
      list: copyList,
    },
  });
}

function* graphNodes() {
  yield takeEvery(GET_ACTORS.REQUEST, getActors);
  yield takeEvery(GET_ACTOR.REQUEST, getActor);
  yield takeEvery(GET_ACTOR_VIEW_DATA.REQUEST, getActorViewData);
  yield takeEvery(GET_ACTOR_BY_REF.REQUEST, getActorByRef);
  yield takeEvery(GET_ITEMS_BY_IDS.REQUEST, getItemsByIds);
  yield takeEvery(GET_LINKED_ACTORS.REQUEST, getLinkedActors);
  yield takeEvery(GET_LINKED_FORMS.REQUEST, getLinkedForms);
  yield takeEvery(SEARCH_LINKED_ACTORS.REQUEST, searchLinkedActors);
  yield takeLeadingBy(CREATE_ACTOR.REQUEST, createActor);
  yield takeEvery(UPDATE_ACTOR.REQUEST, updateActor);
  yield takeEvery(BULK_UPDATE_ACTORS_FIELD.REQUEST, bulkUpdateActorsField);
  yield takeEvery(
    BULK_UPDATE_ACTORS_SETTINGS.REQUEST,
    bulkUpdateActorsSettings
  );
  yield takeEvery(REMOVE_ACTOR_FORMS.REQUEST, removeActorForms);
  yield takeEvery(REMOVE_ALL_FORM_ACTORS.REQUEST, removeAllFormActors);
  yield takeEvery(REMOVE_ACTOR.REQUEST, removeActor);
  yield takeEvery(ADD_ACTOR.REQUEST, addActor);
  yield takeEvery(SEND_ACTORS_DATA.REQUEST, sendActorsData);
  yield takeEvery(VALENCY_GRAPH.GET.REQUEST, getValencyActors);
  yield takeEvery(ACTORS_ACCESS.REQUEST, manageActorsAccess);
  yield takeEvery(GET_ACTORS_BALANCE.REQUEST, getActorsBalance);
  yield takeEvery(GET_ACTOR_TPL.REQUEST, getActorTpl);
  yield takeEvery(STARRED_ACTOR.REQUEST, starredActor);
  yield takeEvery(COPY_ACTOR.REQUEST, copyActor);
}

export default graphNodes;
