import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  Label,
  Menu,
  MenuItem,
  Tooltip,
  DateUtils,
  Stack,
  cr,
} from 'mw-style-react';
import { DATE_FORMAT_6, DATE_FORMAT_7 } from 'constants';
import { TRANSACTION_ACTION_BY_TYPE } from '@control-front-end/common/constants/transactionsFilters';
import {
  ACCOUNT_TYPE,
  INCOME_TYPE,
} from '@control-front-end/common/constants/actorAccounts';
import AppUtils from '@control-front-end/utils/utils';
import FormUtils from '@control-front-end/utils/formUtils';
import cn from 'classnames';
import { useIntl, useMobileViewport } from 'hooks';
import tableScss from '@control-front-end/common/styles/table.scss';
import { AccountAmountValue, TransactionStatus, CopyIdChip } from 'components';
import CollapsibleText from '@control-front-end/common/components/CollapsibleText';
import IncomeTypeChip from '@control-front-end/common/components/IncomeTypeChip';
import CorrectiveTransaction from './components/CorrectiveTransaction';
import mes from './intl';
import scss from './TransactionsList.scss';

const TRANSACTIONS_HISTORY_COLUMN_SIZE = [
  96, // date
  136, // id and ref id
  '20%', // actor title
  124, // status
  '20%', // comment
  72, // income type
  172, // amount
  32, // menu
];

function TransactionsList(props) {
  const {
    debitId,
    creditId,
    transactions,
    currencyName,
    currencyParams,
    readOnly,
    formField,
    transactionType,
    handleCorrect,
  } = props;
  const t = useIntl();
  const [menu, toggleMenu] = useState(null);
  const [correctiveItem, setCorrective] = useState(null);
  const accId = useSelector((state) => state.accounts.active);
  const isMobile = useMobileViewport();
  const system = transactionType !== ACCOUNT_TYPE.number;
  const isAllowedComment =
    transactionType === ACCOUNT_TYPE.number ||
    transactionType === ACCOUNT_TYPE.formField;

  const columnSize = isMobile
    ? AppUtils.getMobileColumnSizes(TRANSACTIONS_HISTORY_COLUMN_SIZE)
    : TRANSACTIONS_HISTORY_COLUMN_SIZE;

  const handleOpenTransaction = (transaction) => {
    const transactionViewLink = AppUtils.makeUrl(
      `/actors_graph/${accId}/${
        formField
          ? TRANSACTION_ACTION_BY_TYPE.formField
          : TRANSACTION_ACTION_BY_TYPE.number
      }/view/${transaction.id}`
    );
    AppUtils.openTabWithNewId(transactionViewLink);
  };

  /**
   * Обработка клика в меню транзакции
   */
  const handleClickMenu = ({ value, transaction }) => {
    switch (value) {
      case 'details':
        handleOpenTransaction(transaction);
        break;
      case 'correct':
        setCorrective(menu);
        break;
      default:
    }
    toggleMenu(null);
  };

  const getMenuItemVisibility = (conditionHidden, conditionDisabled) => {
    if (conditionHidden) {
      return 'hidden';
    }
    return conditionDisabled ? 'disabled' : 'visible';
  };

  /**
   * Отрисовать меню для транзакции
   */
  const renderMenu = (transaction) => {
    return (
      <div className={tableScss.itemMenu} onClick={(e) => e.stopPropagation()}>
        <Menu
          onClick={({ value }) => handleClickMenu({ value, transaction })}
          onClose={() => toggleMenu(null)}
        >
          <MenuItem
            label={t(mes.createCorrective)}
            value="correct"
            visibility={getMenuItemVisibility(!!system, readOnly)}
          />
          <MenuItem label={t(mes.transactionDetails)} value="details" />
        </Menu>
      </div>
    );
  };

  const formattedDate = (unixMs, label) => {
    const unixtime = unixMs / 1000;
    return (
      <>
        <Stack.H size="xxsmall">
          {label ? <Label value={label} className={scss.dateLabel} /> : null}
          <Label value={DateUtils.toDate(unixtime, DATE_FORMAT_6)} />
        </Stack.H>
        <Label
          fontSize="small"
          value={DateUtils.toDate(unixtime, DATE_FORMAT_7)}
        />
      </>
    );
  };

  // Шапка таблицы транзакций
  const renderTableHead = () => (
    <TableHead fixed={true}>
      <TableRow className={tableScss.row} rowSize="small">
        {['date', 'idAndRefId', 'actorName', 'status'].map((key) => (
          <TableCell className={tableScss.cell} key={key}>
            {t(mes[key])}
          </TableCell>
        ))}
        <TableCell
          className={tableScss.cell}
          visibility={isAllowedComment ? 'visible' : 'hidden'}
        >
          {t(mes.comment)}
        </TableCell>
        <TableCell
          className={tableScss.cell}
          visibility={system ? 'hidden' : 'visible'}
        >
          {t(mes.transactionType)}
        </TableCell>
        <TableCell className={tableScss.cell}>
          {system ? t(mes.total) : t(mes.amountAndFee)}
        </TableCell>
        <TableCell className={tableScss.menu} />
      </TableRow>
    </TableHead>
  );

  const renderFormFieldAmount = (value) => {
    if (!formField) return null;
    return (
      <Stack.V className={scss.formField} size="xxsmall" fullWidth>
        {FormUtils.getFormItemValue(
          { ...formField, class: formField.class, value },
          accId,
          {
            interactiveChips: false,
            className: scss.fieldValueTotal,
          }
        )}
      </Stack.V>
    );
  };

  const renderTransactionRow = (transaction) => {
    const {
      id,
      ref,
      type,
      amount,
      commission,
      incomeType = INCOME_TYPE.debit,
      createdAt,
      originalDate,
      expiration,
      title,
      actorTitle,
      isDone,
      handleClick = true,
      handleDetails = true,
    } = transaction;
    const comment = transaction.comment || transaction.data;
    return (
      <TableRow
        key={id}
        id={id}
        className={cn(tableScss.row, {
          [tableScss.clickable]: handleClick,
          [tableScss.disabled]: type === 'removed',
        })}
        columnSize={columnSize}
        onClick={
          handleClick ? () => handleOpenTransaction(transaction) : () => {}
        }
      >
        <TableCell className={tableScss.cell}>
          <Stack.V size={Stack.SIZE.xxsmall}>
            {formattedDate(createdAt)}
            {cr(
              [
                originalDate && createdAt !== originalDate,
                () => formattedDate(transaction.originalDate),
              ],
              [
                expiration,
                () => formattedDate(expiration * 1000, `${t(mes.exp)}:`),
              ],
              true
            )}
          </Stack.V>
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Stack.V className={scss.chips} size={Stack.SIZE.xxsmall}>
            <CopyIdChip fontSize="small" type="transaction" id={id} unspaced />
            {cr(
              [
                transaction.ref,
                <CopyIdChip fontSize="small" type="ref" id={ref} unspaced />,
              ],
              [true, '—']
            )}
          </Stack.V>
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Label
            fontWeight="semibold"
            overflow="cut"
            duplicatingTooltip
            value={system ? actorTitle : title}
          />
        </TableCell>
        <TableCell className={tableScss.cell}>
          <TransactionStatus type={type} isDone={isDone} />
        </TableCell>
        <TableCell
          className={tableScss.cell}
          visibility={isAllowedComment ? 'visible' : 'hidden'}
        >
          {cr(
            [
              comment?.length,
              () => (
                <Tooltip topLevel value={comment}>
                  <div>
                    <CollapsibleText
                      id={`${id}_comment`}
                      text={comment}
                      innerElement="p"
                      buttons={false}
                    />
                  </div>
                </Tooltip>
              ),
            ],
            [true, '—']
          )}
        </TableCell>
        <TableCell
          className={tableScss.cell}
          visibility={system ? 'hidden' : 'visible'}
        >
          <IncomeTypeChip incomeType={incomeType} />
        </TableCell>
        <TableCell className={tableScss.cell}>
          {cr(
            [
              transactionType === ACCOUNT_TYPE.formField,
              renderFormFieldAmount(transaction.nextValue),
            ],
            [
              transactionType === ACCOUNT_TYPE.roles,
              () =>
                transaction.nextValue.map((i) =>
                  FormUtils.getActorChip(
                    { value: i.actorId, title: i.actorTitle },
                    accId
                  )
                ),
            ],
            [
              true,
              <AccountAmountValue
                amount={amount}
                commission={commission}
                currencyParams={currencyParams}
              />,
            ]
          )}
        </TableCell>
        <TableCell className={tableScss.menu}>
          {cr(
            [
              handleDetails,
              <Icon
                type="more"
                onClick={(e) => {
                  e.stopPropagation();
                  if (readOnly) return;
                  toggleMenu(id);
                }}
                visibility={readOnly ? 'disabled' : 'visible'}
              />,
            ],
            [true, <i />]
          )}
          {cr([menu === id, () => renderMenu(transaction)])}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table
      className={tableScss.table}
      paddingSize="small"
      scrolled={isMobile}
      columnSize={columnSize}
    >
      {renderTableHead()}
      <TableBody>
        {transactions.map((item) => (
          <Fragment key={item.id}>
            {renderTransactionRow(item)}
            {cr([
              correctiveItem === item.id,
              <CorrectiveTransaction
                accountId={item.incomeType === 'debit' ? debitId : creditId}
                parentId={item.id}
                total={item.total}
                incomeType={item.incomeType}
                currencyName={currencyName}
                currencyParams={currencyParams}
                onClose={() => setCorrective(null)}
                onSubmit={handleCorrect}
              />,
            ])}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
}

TransactionsList.propTypes = {
  debitId: PropTypes.string,
  creditId: PropTypes.string,
  transactions: PropTypes.array,
  currencyName: PropTypes.string,
  currencyParams: PropTypes.object,
  readOnly: PropTypes.bool,
  transactionType: PropTypes.oneOf(Object.values(ACCOUNT_TYPE)),
  handleCorrect: PropTypes.func,
};

export default TransactionsList;
