import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  cr,
  Divider,
  Icon,
  Label,
  Space,
  Stack,
  Tooltip,
} from 'mw-style-react';
import cn from 'classnames';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import EmptyList from '@control-front-end/common/components/EmptyList';
import { useIntl, useMobileViewport } from 'hooks';
import { PARTICIPANT_TYPE } from 'constants';
import scss from './ResultList.scss';
import mes from './intl';

function ResultList({
  objType,
  title,
  list,
  isSearch,
  onScroll,
  onSelect,
  onDeselect,
  onClickRightElement,
}) {
  const listRef = useRef();
  const t = useIntl();
  const isMobile = useMobileViewport();

  const renderList = () => (
    <Stack.V
      forwardRef={listRef}
      className={scss.resultList}
      size="none"
      fullWidth
      fullHeight
      divider={<Divider />}
      onScroll={onScroll}
    >
      {list.map((item) => (
        <Tooltip
          key={`${item.id}_${objType}_tooltip`}
          value={item.isExcluded ? t(mes[`${objType}AlreadyAdded`]) : ''}
          topLevel
        >
          <Space
            key={`${item.id}_${objType}`}
            className={cn(scss.resultListItem, {
              [scss.active]: item.isActive,
              [scss.disabled]: item.isExcluded,
            })}
            size="xxsmall"
          >
            <Stack.H
              size="xsmall"
              alignItems="center"
              justifyContent="spaceBetween"
              fullWidth
              onClick={() => {
                if (item.isExcluded) return;
                if (item.isActive) onDeselect(item);
                else onSelect(item);
              }}
            >
              <div className={scss.resultListItem__avatar__box}>
                <UserAvatar
                  className={scss.resultListItem__avatar}
                  size="small"
                  {...item.userAvatar}
                />
              </div>
              <Stack.V size="micro" fullWidth>
                <Label overflow="cut" duplicatingTooltip value={item.name} />
                {cr([
                  item.info,
                  <Label
                    fontSize="small"
                    overflow="cut"
                    color={Label.COLOR.gray}
                    value={item.info}
                  />,
                ])}
              </Stack.V>
              <Stack.H
                className={scss.resultListItem__rightEl}
                size="xsmall"
                alignItems="center"
                justifyContent="flexEnd"
              >
                {cr(
                  [
                    item.count,
                    <Label
                      className={scss.resultListItem__counter}
                      value={item.count}
                      color={Label.COLOR.primary}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickRightElement(item);
                      }}
                    />,
                  ],
                  [item.isActive, <Icon type="check" size="small" />],
                  [item.isExcluded, <Icon type="check_box" />],
                  true
                )}
              </Stack.H>
            </Stack.H>
          </Space>
        </Tooltip>
      ))}
    </Stack.V>
  );

  return (
    <Space size={isMobile ? 'xxsmall' : 'small'} left right fullWidth>
      <Stack.V size="xsmall" fullHeight fullWidth>
        <Label value={title} fontSize="semibold" />
        {cr(
          [list.length, () => renderList()],
          [
            true,
            <EmptyList size="small" objType={objType} isSearch={isSearch} />,
          ]
        )}
      </Stack.V>
    </Space>
  );
}

ResultList.propTypes = {
  objType: PropTypes.oneOf(Object.values(PARTICIPANT_TYPE)),
  title: PropTypes.string,
  list: PropTypes.array,
  isSearch: PropTypes.bool,
  onScroll: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onClickRightElement: PropTypes.func,
};

export default ResultList;
