import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Notify, PopoverBoundary } from 'mw-style-react';

import { HIDE_NOTIFY } from 'constants';
import { Modal, Menu, SlideoutMenu, Widget } from 'components';
import getURLSettings from '@control-front-end/utils/getURLSettings';

import '../../../styles/main.scss';

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.handleNotifyClose = this.handleNotifyClose.bind(this);
    this.__showSidebar = getURLSettings().UI.sidebar;
  }

  componentDidUpdate() {
    const { notify, dispatch, goToUndefPage } = this.props;
    goToUndefPage(notify, dispatch);
  }

  // Отображение формы модального окна
  renderModal() {
    return this.props?.modal?.map?.((m, index) => (
      <Modal key={m.modalId || `${m.name}${index}`} {...m} />
    ));
  }

  // Отображение widgets
  renderWidgets() {
    return this.props?.widgets?.map?.((m) =>
      m ? <Widget key={m.id} {...m} /> : null
    );
  }

  handleNotifyClose(id) {
    this.props.dispatch({ type: HIDE_NOTIFY.REQUEST, payload: { id } });
  }

  // Отображение нотификации
  renderNotify() {
    const notify = this.props.notify || {};
    const items = notify.items || [];
    return (
      <Notify
        styleName="notify"
        value={items}
        onClose={this.handleNotifyClose}
      />
    );
  }

  render() {
    const { children, theme, settings } = this.props;
    return (
      <PopoverBoundary.Consumer>
        {({ globalBoundaryRef }) => (
          <div ref={globalBoundaryRef} className={`theme-${theme} theme`}>
            {settings.loaded ? (
              <div styleName="main">
                <div styleName="main__root" id="mainRoot">
                  <div styleName="main__contentWrap">
                    {this.__showSidebar ? <Menu /> : null}
                    <div id="mainContent" styleName="main__content">
                      {children}
                    </div>
                  </div>
                  {this.__showSidebar ? <SlideoutMenu /> : null}
                </div>
              </div>
            ) : null}
            {this.renderModal()}
            {this.renderWidgets()}
            {this.renderNotify()}
          </div>
        )}
      </PopoverBoundary.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme,
  modal: state.modal,
  widgets: state.widgets,
  accounts: state.accounts,
  settings: state.settings,
  notify: state.notify,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

MainLayout.propTypes = {
  theme: PropTypes.string,
  accounts: PropTypes.object,
  settings: PropTypes.object,
  modal: PropTypes.array,
  children: PropTypes.node.isRequired,
  notify: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  goToUndefPage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainLayout));
