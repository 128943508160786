import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Label,
  TextField,
  Tooltip,
  Icon,
  Upload,
  Avatar,
  Divider,
  Select,
  MenuItem,
  Space,
} from 'mw-style-react';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import {
  UPLOAD_FILE,
  SCRIPT_SHARE_TYPES,
} from '@control-front-end/common/constants';
import { RE_APP_SHORT_NAME } from '@control-front-end/common/constants/regExp';
import useIntl from 'useIntl';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sLocal from './ScriptSettings.scss'; // NOSONAR

function ScriptSettings({ values, errors, handleOnChange }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const pictureUrl = values.picture
    ? AppUtils.makeAppUrl(`/download/${values.picture}`)
    : '';

  const handleUploadPicture = ({ value }) => {
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files: value,
        callback: (attachments) => {
          const picture = attachments[0].fileName;
          handleOnChange({ id: 'picture', value: picture });
        },
      },
    });
  };

  const handleTitleChange = ({ value }) => {
    handleOnChange({ id: 'title', value });
    const invalidSymbolsRegexp = new RegExp('[^0-9a-zA-Z_-\\s]', 'ig');
    const scriptTitle = AppUtils.transliterate(value).replace(
      invalidSymbolsRegexp,
      ''
    );
    const ref = scriptTitle.split(' ').join('-').toLowerCase();
    setTimeout(
      () => handleOnChange({ id: 'ref', value: ref, error: false }),
      0
    );
  };

  const renderShareTypes = () =>
    SCRIPT_SHARE_TYPES.map((shareType) => (
      <MenuItem
        key={shareType}
        label={t(mes[`scriptSharedWith_${shareType}`]) || ''}
        value={shareType}
      />
    ));

  return (
    <div styleName="sLocal.settings">
      <div styleName="sLocal.row">
        <Tooltip value={t(mes.shortNameHint)} breakWord topLevel>
          <Icon styleName="sLocal.settings__info" type="info" size="small" />
        </Tooltip>
        <div styleName="sLocal.settings__picture">
          <Upload
            styleName={cn('sLocal.settings__picture__upload', {
              'sLocal.loaded': !!values.picture,
            })}
            type="avatar"
            accept="image/*"
            onChange={handleUploadPicture}
          >
            <Avatar
              size="xxlarge"
              bgColor="#FFF"
              src={values.picture ? pictureUrl : ''}
            />
          </Upload>
        </div>
        <div styleName="sLocal.settings__main">
          <TextField
            id="title"
            styleName="sLocal.row__field"
            autoFocus={true}
            bordered={true}
            label={t(mes.title)}
            value={values.title || ''}
            error={!!errors.title}
            helperText={t(mes[errors.title])}
            onChange={handleTitleChange}
          />
          <TextField
            id="shortName"
            styleName="sLocal.row__field"
            bordered={true}
            label={t(mes.shortName)}
            value={values.shortName || ''}
            error={!!errors.shortName}
            helperText={t(mes[errors.shortName])}
            onChange={handleOnChange}
          />
          <TextField
            id="description"
            styleName="sLocal.row__field"
            bordered={true}
            label={t(mes.description)}
            value={values.description || ''}
            error={!!errors.description}
            onChange={handleOnChange}
          />
          <Space top bottom size="large">
            <Select
              styleName="sLocal.row__field"
              label={t(mes.sharedWith)}
              helperText=""
              bordered={true}
              unspaced
              value={values.sharedWith[0].value}
              onChange={({ value }) => {
                handleOnChange({
                  id: 'sharedWith',
                  value: [
                    { value, title: t(mes[`scriptSharedWith_${value}`]) },
                  ],
                });
              }}
            >
              {renderShareTypes()}
            </Select>
          </Space>
        </div>
      </div>
      <Divider styleName="sLocal.row__divider" />
      <Label
        styleName="sLocal.row__label"
        fontWeight="semibold"
        value={t(mes.corCredentials)}
      />
      <div styleName="sLocal.settings__credentials">
        {['apiLogin', 'apiSecret', 'companyId', 'devProcId', 'prodProcId'].map(
          (field) => {
            let fieldType = field === 'apiSecret' ? 'password' : 'text';
            if (['apiLogin', 'devProcId', 'prodProcId'].includes(field)) {
              fieldType = 'int';
            }
            return (
              <TextField
                key={field}
                id={field}
                styleName="sLocal.row__field"
                bordered={true}
                label={t(mes[field])}
                value={values[field] || ''}
                error={!!errors[field]}
                type={fieldType}
                regexp={field === 'ref' ? RE_APP_SHORT_NAME : null}
                helperText={t(mes[errors[field]])}
                onChange={handleOnChange}
              />
            );
          }
        )}
      </div>
    </div>
  );
}

ScriptSettings.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleOnChange: PropTypes.func,
};

export default ScriptSettings;
