import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'hooks';
import { Space, Stack, Button, Icon, cr } from 'mw-style-react';
import Toggle from '@control-front-end/common/components/Toggle';
import TemplateFields from '../TemplateFields';
import mes from './intl';

const OR = '||';

/**
 * Filter query OR conditions
 */
function ConditionBlocks(props) {
  const { q, formId, onChange, onRemoveCondition } = props;
  const t = useIntl();
  const [conditions, setConditions] = useState(['']);

  useEffect(() => {
    if (q) setConditions(decodeURIComponent(q).split(OR));
  }, [q]);

  /**
   * Handle changing in condition block
   */
  const handleOnChange = useCallback(
    ({ id, value, error }, index) => {
      const newConds = [...conditions];
      newConds[index] = decodeURIComponent(value);
      const newValue = newConds.join(OR);
      onChange({ id, value: encodeURIComponent(newValue), error });
    },
    [conditions]
  );

  /**
   * Add condition block
   */
  const handleAddCondition = useCallback(() => {
    setConditions((conds) => [...conds, '']);
  }, [conditions]);

  /**
   * Remove condition block
   */
  const handleRemoveCondition = useCallback(
    (index) => {
      const newConds = conditions.filter((_, i) => i !== index).join(OR);
      onChange({ id: 'q', value: encodeURIComponent(newConds), error: false });
      // TODO Since for the parameter "q" we operate with a string and not an array of objects,
      // when rendering the list of conditions, we do not have the id of this condition,
      // which leads to unexpected errors. It is necessary to change the storage structure of term "q".
      // Now, when removing the condition, you have to re-render the component.
      onRemoveCondition();
    },
    [conditions]
  );

  /**
   * Render condition blocks
   */
  const renderConditions = () => {
    return conditions.map((i, index) => (
      <Toggle
        key={index}
        isOpen
        title={t(mes.actorFilterConditionBlock, { index: index + 1 })}
        rightComponent={cr([
          conditions.length > 1,
          <span onClick={() => handleRemoveCondition(index)}>
            <Icon type="close" size="small" />
          </span>,
        ])}
      >
        <Space top>
          <TemplateFields
            {...props}
            formId={formId}
            q={i}
            onChange={(value) => handleOnChange(value, index)}
          />
        </Space>
      </Toggle>
    ));
  };

  if (!formId) return null;
  return (
    <Space bottom>
      {renderConditions()}
      <Stack.H fullWidth justifyContent="center">
        <Button
          type={Button.TYPE.secondary}
          size={Button.SIZE.smallplus}
          label={t(mes.createActorFilterCondBlock)}
          icon="plus"
          fullWidth={false}
          onClick={handleAddCondition}
        />
      </Stack.H>
    </Space>
  );
}

ConditionBlocks.propTypes = {
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemoveCondition: PropTypes.func.isRequired,
};

export default ConditionBlocks;
