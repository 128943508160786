import { call, select } from 'redux-saga/effects';
import { Utils } from 'mw-style-react';

import { SYSTEM_SPECIAL_LAYERS } from '@control-front-end/common/constants/graphLayers';
import AppUtils from '@control-front-end/utils/utils';
import { toCellCoord } from '@control-front-end/utils/modules/utilsCellCoords';

import {
  getGraphEls,
  makeActorPicture,
  makeUserWithAvatar,
} from '../../../../sagas/graph/graphHelpers';

/**
 * Обновить поле слоя
 */
export function* updateLayerProp({ layerId, propId, value }) {
  const graphL = yield select((state) => state.graphLayers);
  const copyList = graphL.list.slice();
  const layerIndex = copyList.findIndex((i) => i.id === layerId);
  const layer = copyList[layerIndex];
  if (!layer) return copyList;
  const newLayer = { ...layer, [propId]: value };
  newLayer.nodes = yield call(getGraphEls, 'nodes', layerId);
  newLayer.edges = yield call(getGraphEls, 'edges', layerId);
  copyList.splice(layerIndex, 1, newLayer);
  return copyList;
}

/**
 * Поменять св-ва слоя
 */
export function* updateLayerProps({ layerId, props }) {
  const graphL = yield select((state) => state.graphLayers);
  const copyList = graphL.list.slice();
  const layerIndex = copyList.findIndex((i) => i.id === layerId);
  const layer = copyList[layerIndex];
  if (!layer) return copyList;
  copyList.splice(layerIndex, 1, { ...layer, ...props, isNew: false });
  return copyList;
}

/**
 * Добавить настройки слоя
 */
export function* makeLayerSettings(model) {
  model.pictureUrl = yield call(makeActorPicture, model);
  model.pictureOpacity = model.pictureOpacity || 0.7;
  if (!model.nodes) return;
  model.layerView = 'graphMarkup';
}

/**
 * Создать модель слоя
 */
export function* makeLayerModel(models, typeLayer) {
  const config = yield select((state) => state.config);
  const auth = yield select((state) => state.auth);
  models = Array.isArray(models) ? models : [models];
  for (const model of models) {
    yield call(makeLayerSettings, model);
    model.data = model.data || {};
    model.layerId = model.layerId || model.id;
    model.key = model.key || AppUtils.udid();
    model.isCustom =
      !model.isSystem ||
      (model.isSystem && !SYSTEM_SPECIAL_LAYERS.includes(model.name));
    if (model.user) {
      model.user = makeUserWithAvatar(model.user, config);
    }
    if (!model.access) {
      model.access = [AppUtils.makeAuthUserAsOwner(auth)];
    }
    if (typeLayer) model.typeLayer = typeLayer;
    if (model.isSystem) {
      const underN = Utils.toUnderscoreCase(model.name).split('_');
      model.title = underN.map((i) => Utils.capitalize(i)).join(' ');
    }
    for (const a of model.access) {
      a.avatar = AppUtils.makeUserAvatar(a, config);
    }
  }
}

/**
 * Сохранить позиции узлов слоя
 */
export function* saveNodePositions({ layerId, positions }) {
  const copyNodes = yield call(getGraphEls, 'nodes', layerId);
  const pObj = {};
  const cpObj = {};
  positions.forEach((i) => {
    pObj[i.id] = i.position;
    cpObj[i.id] = toCellCoord(i.position);
  });
  copyNodes.forEach((node) => {
    const nodeId = node.data.laId ? node.id.split('_')[1] : node.id;
    node.position = pObj[nodeId] || node.position;
    node.data.position = pObj[nodeId] || node.position;
    node.cellPosition = cpObj[nodeId] || node.cellPosition;
    node.data.cellPosition = cpObj[nodeId] || node.data.cellPosition;
    if (pObj[nodeId]) node.status = 'updated';
  });
  return structuredClone(copyNodes);
}

/**
 * Получить активный слой
 */
export function* getActiveLayer() {
  const graphL = yield select((state) => state.graphLayers);
  return graphL.list.find((i) => i.id === graphL.active);
}

/**
 * Get transfers edges weight
 */
export function normalizeTransfersEdgesWeight(edges) {
  const weights = edges.map((i) => i.weight);
  const maxW = Math.max(...weights);
  const minW = Math.min(...weights);
  const minOutput = 2;
  const maxOutput = 10;
  const map = {};
  for (const i of edges) {
    map[i.id] = AppUtils.numberNormalize(
      i.weight,
      minW,
      maxW,
      minOutput,
      maxOutput
    );
  }
  return map;
}
