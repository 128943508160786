import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Label, TextField, Tooltip, Stack } from 'mw-style-react';
import { ActorAvatarEditable } from 'components';
import { useIntl } from 'hooks';
import SingleAccountUser from './components/SingleAccountUser';
import './ActorGeneralFields.scss';
import mes from './intl';

function ActorGeneralFields({
  refId,
  systemObjId,
  showOptional = true,
  isSystemForm = false,
  title,
  pictureUrl,
  colors,
  onChange,
}) {
  const t = useIntl();

  /**
   *  Изменить изображение для актора
   */
  const managePicture = (actorData) => {
    if (actorData.picture || actorData.picture === null) {
      onChange({
        picture: actorData.picture,
        pictureUrl: actorData.pictureUrl,
      });
    } else if (actorData.color || actorData.color === null) {
      onChange({ color: actorData.color });
    }
  };

  return (
    <div styleName="agf">
      <div styleName="agf__avatar">
        <Label fontWeight="semibold" value={t(mes.actorAvatar)} />
        <ActorAvatarEditable
          size="xxlarge"
          pictureUrl={pictureUrl}
          colorFilled={true}
          colors={colors}
          iconLabel={t(mes.addImageOrColor)}
          handleChange={managePicture}
        />
      </div>
      <div styleName="agf__data">
        <TextField
          styleName="agf__data__field"
          autoFocus={!title}
          bordered={true}
          label={t(mes.actorName)}
          value={title}
          onChange={({ value }) => onChange({ title: value })}
        />
        {showOptional ? (
          <>
            <div styleName="agf__data__ref">
              <Tooltip
                topLevel
                value={
                  <Stack.V size="none" alignItems="center">
                    <Label fontSize="small" value={t(mes.refInfo)} />
                    <Label
                      fontSize="small"
                      fontWeight="semibold"
                      value={t(mes.refInfoWarning)}
                    />
                  </Stack.V>
                }
              >
                <Icon
                  styleName="agf__data__ref__icon"
                  size="small"
                  type="info"
                />
              </Tooltip>
              <TextField
                styleName="agf__data__field"
                bordered={true}
                label={t(mes.refId)}
                value={refId || ''}
                onChange={({ value }) => onChange({ ref: value })}
              />
            </div>
            {!isSystemForm ? (
              <SingleAccountUser userId={systemObjId} onChange={onChange} />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

ActorGeneralFields.propTypes = {
  title: PropTypes.string,
  refId: PropTypes.string,
  systemObjId: PropTypes.string,
  showOptional: PropTypes.bool,
  isSystemForm: PropTypes.bool,
  pictureUrl: PropTypes.string,
  colors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default ActorGeneralFields;
