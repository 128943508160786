import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import { Label, Icon, Tooltip, Utils, Stack } from 'mw-style-react';
import cn from 'classnames';
import UsersAvatars from '@control-front-end/common/components/UsersAvatars';
import './AccessDiffValue.scss';
import mes from './intl';

const ACCESS_ICON = {
  view: 'eye',
  modify: 'edit',
  remove: 'trash',
  sign: 'sign',
  execute: 'check',
};

/**
 * One actor data change row
 * @param props
 * @returns {*}
 * @constructor
 */
function AccessDiffValue(props) {
  const { id, field, prevValue, nextValue } = props;
  const t = useIntl();
  const config = useSelector((state) => state.config);

  /**
   * Colorful icons for user privs
   */
  const renderPrivsIcons = useCallback(
    (privs, disabled = false) => (
      <Stack
        styleName={cn('accessDiff__item__privs', { disabled })}
        horizontal
        align="center"
        size={Stack.SIZE.xxsmall}
      >
        {privs.map((priv) => (
          <Tooltip
            key={`${id}_${priv}`}
            topLevel
            value={Utils.toUpperLatter(priv)}
          >
            <Icon styleName={priv} type={ACCESS_ICON[priv]} />
          </Tooltip>
        ))}
      </Stack>
    ),
    []
  );

  /**
   * Return users with changed privs
   */
  const getChangedPrivs = (prev, next) => {
    const diff = [];
    prev.forEach((i) => {
      const fUser = next.find((j) => i.userId === j.userId);
      if (fUser && JSON.stringify(fUser.privs) !== JSON.stringify(i.privs))
        diff.push({ ...fUser, prevPrivs: i.privs });
    });
    return diff;
  };

  /**
   * Отрисовать изменение доступов
   */
  const renderAccessDiff = useCallback(
    ({ type, list }) => (
      <Stack styleName="accessDiff__item__list" size={Stack.SIZE.xsmall}>
        {list.map((i) => {
          const avatar = AppUtils.makeUserAvatar(i, config);
          const activePrevPrivs = i.prevPrivs
            ? Object.keys(i.prevPrivs).filter((key) => !!i.prevPrivs[key])
            : null;
          const disabledUser = type === 'removed';
          return (
            <Stack
              key={`${id}_${field}_${type}_${i.id || i.userId}`}
              horizontal
              alignItems="center"
              size={Stack.SIZE.xsmall}
            >
              <Stack horizontal alignItems="center" size={Stack.SIZE.none}>
                <UsersAvatars size="small" users={[{ ...i, avatar }]} />
                <Label
                  styleName={cn('accessDiff__item__nick', {
                    strikethrough: disabledUser,
                  })}
                  overflow="cut"
                  value={i.nick}
                />
              </Stack>
              {activePrevPrivs
                ? renderPrivsIcons(activePrevPrivs, disabledUser)
                : null}
              {type === 'changed' ? (
                <Icon
                  styleName="accessDiff__item__arrow"
                  size="small"
                  type="arrow_thin"
                />
              ) : null}
              {renderPrivsIcons(i.activePrivs, disabledUser)}
            </Stack>
          );
        })}
      </Stack>
    ),
    []
  );

  const renderGroupedAccessDiff = useCallback(({ type, list }) => {
    if (!list.length) return null;
    const withActivePrivs = list.map((a) => {
      const activePrivs = Object.keys(a.privs).filter((key) => !!a.privs[key]);
      const privsCount = activePrivs.length;
      return { ...a, activePrivs, privsCount };
    });
    const sortedAccess = AppUtils.sort(withActivePrivs, 'privsCount', 'desc');
    return (
      <Stack
        key={`${id}_${field}_${type}`}
        styleName="accessDiff__item"
        horizontal
        size={Stack.SIZE.none}
      >
        <Label color={Label.COLOR.gray} value={t(mes[`${type}_access`])} />
        {renderAccessDiff({ type, list: sortedAccess })}
      </Stack>
    );
  }, []);

  const added = useMemo(
    () => AppUtils.diffArrayObj(nextValue, prevValue, 'userId'),
    [prevValue, nextValue]
  );
  const removed = useMemo(
    () => AppUtils.diffArrayObj(prevValue, nextValue, 'userId'),
    [prevValue, nextValue]
  );
  const changed = useMemo(
    () => getChangedPrivs(prevValue, nextValue),
    [prevValue, nextValue]
  );

  return (
    <Stack key={`${id}_${field}_users`} styleName="accessDiff">
      {renderGroupedAccessDiff({ type: 'removed', list: removed })}
      {renderGroupedAccessDiff({ type: 'changed', list: changed })}
      {renderGroupedAccessDiff({ type: 'added', list: added })}
    </Stack>
  );
}

AccessDiffValue.propTypes = {
  id: PropTypes.number.isRequired,
  field: PropTypes.string,
  prevValue: PropTypes.any,
  nextValue: PropTypes.any,
};

export default AccessDiffValue;
