import { put, select, takeLatest } from 'redux-saga/effects';
import { Utils } from 'mw-style-react';

import {
  WS_EXTERNAL_PACKET,
  EXTERNAL_PACKET_ACTION,
  REACTION_STORAGE_KEY,
} from '@control-front-end/common/constants/reactions';
import AppUtils from '@control-front-end/utils/utils';
import history from '@control-front-end/app/src/store/history';
import { SET_ACTIVE_ACTOR } from '@control-front-end/common/constants/graphActors';

const PAGE_REGEX = {
  actorView: /\/actors_graph\/([^/]+)\/view\/([^/]+)/,
  events: /\/events\/([^/]+)\/list/,
};

function* handleExternalPacket({ payload = {} }) {
  const { accId, name, data } = payload;
  const activeAccId = yield select((state) => state.accounts.activeAccount.id);
  if (accId !== activeAccId || !EXTERNAL_PACKET_ACTION[name]) return;

  const url = history.location.pathname;

  if (name === EXTERNAL_PACKET_ACTION['events.setActive']) {
    if (url.match(PAGE_REGEX.actorView)) {
      history.replace(
        AppUtils.makeUrl(
          `/actors_graph/${accId}/view/${data.actorId}${history.location.search}`
        )
      );
      return;
    }
    if (url.match(PAGE_REGEX.events)) {
      yield put({ type: SET_ACTIVE_ACTOR, payload: { id: data.actorId } });
    }
    return;
  }

  if (name === EXTERNAL_PACKET_ACTION['reactions.placeholder']) {
    // Trigger event to set placeholder message to the active reaction form
    if (Object.values(PAGE_REGEX).some((regex) => url.match(regex))) {
      document.dispatchEvent(
        new CustomEvent(
          `${WS_EXTERNAL_PACKET}:${EXTERNAL_PACKET_ACTION['reactions.placeholder']}`,
          { detail: data }
        )
      );
    }
    // Set placeholder message to session storage
    Utils.toSessionStorage(`${REACTION_STORAGE_KEY}${data.actorId}`, data.text);
  }
}

function* wsExternalPacket() {
  yield takeLatest(WS_EXTERNAL_PACKET, handleExternalPacket);
}

export default wsExternalPacket;
