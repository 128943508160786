import { defineMessages } from 'react-intl';

const m = defineMessages({
  actorFilterConditionBlock: {
    id: 'actorFilterConditionBlock',
    defaultMessage: 'Condition block {index}',
  },
  createActorFilterCondBlock: {
    id: 'createActorFilterCondBlock',
    defaultMessage: 'Create new condition block',
  },
});

export default m;
