import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { DATE_FORMAT } from 'constants';
import {
  Label,
  Stack,
  DateUtils,
  Icon,
  Utils,
  Clipboard,
  Tooltip,
  Chip,
} from 'mw-style-react';
import cn from 'classnames';
import useIntl from 'useIntl';
import { useModal, useNotifications } from 'hooks';
import JSONEditorReact from '@control-front-end/common/components/JSONEditorReact';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import mes from './intl';
import './TriggerCall.scss';

/**
 * Trigger call details
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TriggerCall(props) {
  const {
    id,
    actorIdInit,
    url,
    request,
    response,
    httpCode,
    actorTitle,
    actorPictureUrl,
    color,
    accountName,
    currencyName,
    createdAt,
    handleResend,
  } = props;
  const t = useIntl();
  const requestRef = useRef();
  const responseRef = useRef();
  const [openInfo, toggleInfo] = useState(false);
  const [json, setJson] = useState({});
  const { showNotification } = useNotifications();
  const { open: openJSONPreviewModal } = useModal('JsonPreview', {});

  const handleCopyWebhook = useCallback(() => {
    Clipboard.copy(url);
    showNotification('success', t(mes.linkWasCopied));
  }, [url]);

  const name = useMemo(() => {
    const jsonRequest = JSON.parse(request);
    if (!jsonRequest || !jsonRequest.payload) return null;
    const { trigger } = jsonRequest.payload;
    return trigger.title;
  }, [request]);

  useEffect(() => {
    if (!openInfo) return;
    try {
      const parsedReq = JSON.parse(request);
      const parsedResp = JSON.parse(response);
      setJson({
        request: parsedReq,
        response: parsedResp,
      });
    } catch {
      setJson({ request: {}, response: {} });
    }
  }, [openInfo]);

  const renderMainBlock = (label, value) => (
    <div styleName="call__main__block">
      <Label styleName="call__label" fontSize="small" value={label} />
      {typeof value === 'string' ? (
        <Label styleName="call__value" value={value} />
      ) : (
        <div styleName="call__value">{value}</div>
      )}
    </div>
  );

  const renderStatusChip = (code) => {
    const isSuccess = code.toString().match(/200|201/);
    const type = isSuccess ? 'success' : 'error';
    return (
      <Stack horizontal alignItems="center" size={Stack.SIZE.small}>
        <Chip
          styleName={cn('call__status', type)}
          size="small"
          type="rectangular"
          fontWeight="normal"
          label={httpCode}
          closeIcon={false}
          unspaced={true}
          unbordered={true}
        />
        <Tooltip topLevel value={t(mes.resend)}>
          <Icon
            styleName="call__status__resend"
            size="small"
            type="repeat"
            onClick={() => handleResend({ actorId: actorIdInit, callId: id })}
          />
        </Tooltip>
      </Stack>
    );
  };

  const renderJsonView = useCallback(
    (key) => {
      return (
        <div styleName="call__details__json__view">
          <Stack
            horizontal
            alignItems="center"
            justifyContent="spaceBetween"
            size={Stack.SIZE.xsmall}
            fullWidth
          >
            <Label
              styleName="call__label"
              fontSize="small"
              value={Utils.toUpperLatter(key)}
            />
            <div
              onClick={() =>
                openJSONPreviewModal({
                  json: json[key] || {},
                })
              }
            >
              <Icon
                styleName="call__details__json__view__expand"
                size="small"
                type="expand"
              />
            </div>
          </Stack>
          <JSONEditorReact
            jsonEditorRef={key === 'request' ? requestRef : responseRef}
            schema={{}}
            text=""
            json={json[key] || {}}
            mode="code"
            mainMenuBar={true}
            indentation={2}
            onChange={() => {}}
            onError={() => {}}
            onEditable={() => false}
          />
        </div>
      );
    },
    [json]
  );

  return (
    <Stack key={id} styleName="call">
      <Stack
        styleName="call__main"
        horizontal
        fullWidth
        alignItems="center"
        justifyContent="spaceBetween"
        size={Stack.SIZE.small}
      >
        {renderMainBlock(
          t(mes.triggerDate),
          DateUtils.toDate(createdAt / 1000, DATE_FORMAT)
        )}
        {renderMainBlock(t(mes.triggerName), name)}
        {renderMainBlock(
          t(mes.actor),
          <div styleName="call__value__actor">
            <ActorAvatar
              size="micro"
              type="compact"
              pictureUrl={actorPictureUrl}
              colorFilled={true}
              colors={[{ type: 'actor', color }]}
            />
            <Label value={actorTitle} overflow="cut" />
          </div>
        )}
        {renderMainBlock(t(mes.accCurrency), `${accountName}, ${currencyName}`)}
        {renderMainBlock(t(mes.httpCode), renderStatusChip(httpCode))}
        <div
          styleName={cn('call__arrow', { open: openInfo })}
          onClick={() => toggleInfo(!openInfo)}
        >
          <Icon type="arrow" />
        </div>
      </Stack>
      {openInfo ? (
        <Stack styleName="call__details" size={Stack.SIZE.none}>
          <Stack
            styleName="call__details__url"
            horizontal
            justifyContent="spaceBetween"
            alignItems="center"
          >
            <Label fontSize="small" value={`${t(mes.webhook)}: ${url}`} />
            <Tooltip topLevel value={t(mes.copyLink)}>
              <Icon
                type="copy"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyWebhook();
                }}
              />
            </Tooltip>
          </Stack>
          <Stack
            styleName="call__details__json"
            horizontal
            fullWidth
            size={Stack.SIZE.none}
          >
            {renderJsonView('request')}
            {renderJsonView('response')}
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
}

TriggerCall.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string,
  request: PropTypes.string,
  response: PropTypes.string,
  actorTitle: PropTypes.string,
  actorPictureUrl: PropTypes.string,
  color: PropTypes.string,
  accountName: PropTypes.string,
  currencyName: PropTypes.string,
  httpCode: PropTypes.number,
  createdAt: PropTypes.number,
  handleResend: PropTypes.func.isRequired,
};

export default TriggerCall;
