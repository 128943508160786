import {
  keyBy,
  mapValues,
  find,
  isEmpty,
  intersectionWith,
  isEqual,
} from 'lodash';
import { cr } from 'mw-style-react';

import { GRAPH_CELL_SIZE } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import { CUSTOM_EVENTS } from '@control-front-end/common/constants/graph';

export const NODE_SNAP_TYPE = {
  none: 'none',
  center: 'center',
  borders: 'borders',
};

export const getNodeSnapType = (node) => {
  return cr(
    [
      node.isStateMarkup && !node.isTrace && node.polygon,
      NODE_SNAP_TYPE.borders,
    ],
    [node.pictureObject || node.isTrace, NODE_SNAP_TYPE.none],
    [true, NODE_SNAP_TYPE.center]
  );
};

/**
 * WARNING: There is the same cell coordinates rules on the backend side.
 * If you change this rules and logic - then it also should be updated on the backend side.
 * @see https://git.corezoid.com/pong/pong-server/-/blob/develop/src/packages/utils/cellCoords.js
 */
const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const ab = ALPHABET.split('');

const abLen = ab.length;

const toCellCoordCache = {};

/**
 * Converts the given coordinates (x, y) to a cell coordinate with memoization.
 *
 * @param {number} x - The x-coordinate value.
 * @param {number} y - The y-coordinate value.
 * @return {Object} Object containing the converted x and y cell coordinates.
 */
export function toCellCoord({ x, y }) {
  const cacheKey = `${x},${y}`;

  if (toCellCoordCache[cacheKey]) return toCellCoordCache[cacheKey];

  const minusX = x < 0 ? '-' : '';
  let nY = Math.round(y / GRAPH_CELL_SIZE);
  let nX = Math.round(Math.abs(x) / GRAPH_CELL_SIZE);
  nX = x < 0 ? nX - 1 : nX;
  nY = y >= 0 ? nY + 1 : nY;
  const modX = nX % abLen;
  const ceilX = nX / abLen;
  const numX = Math.floor(ceilX) === 0 ? '' : Math.floor(ceilX);
  nX = `${minusX}${ab[modX]}${numX}`;

  const result = { x: nX, y: nY.toString() };
  toCellCoordCache[cacheKey] = result;

  return result;
}

/**
 * Converts the given cell coordinates (x, y) to a geometric coordinate.
 *
 * @param {number} x - The cell x-coordinate value.
 * @param {number} y - The cell y-coordinate value.
 * @return {Object} Object containing the converted cell x and y geometric coordinates.
 */
export function toGeomCoord({ x, y }) {
  let nY = Math.round(y) * GRAPH_CELL_SIZE;
  nY = y >= 0 ? nY - GRAPH_CELL_SIZE : nY;
  const isMinusX = x.startsWith('-');
  const [aX, numX = ''] = x.slice(isMinusX ? 1 : 0).split(/(\d+)/);
  const aIndex = ab.indexOf(aX);
  const nX = (aIndex + +numX * abLen) * GRAPH_CELL_SIZE;
  return { x: isMinusX ? -nX - GRAPH_CELL_SIZE : nX, y: nY };
}

/**
 * Snaps the given geometric coordinates (x, y) to the nearest grid cell.
 *
 * @param {number} x - The x-coordinate value.
 * @param {number} y - The y-coordinate value.
 * @return {Object} An object containing the snapped x and y coordinates.
 */
export function snapGeomCoord({ x, y }) {
  const nX = Math.round(x / GRAPH_CELL_SIZE) * GRAPH_CELL_SIZE;
  const nY = Math.round(y / GRAPH_CELL_SIZE) * GRAPH_CELL_SIZE;
  return { x: nX, y: nY };
}

/**
 * Snaps the given rectangle coordinates to the nearest grid cell.
 *
 * @param {Object} rect - The rectangle coordinates with x1, y1, x2, and y2 properties.
 * @return {Object} The snapped rectangle coordinates with x1, y1, x2, and y2 properties.
 */
export function snapRect(rect) {
  const snapRect = mapValues(rect, (value, key) => {
    const snappedCoord = Math.round(value / GRAPH_CELL_SIZE) * GRAPH_CELL_SIZE;
    return (
      snappedCoord +
      (rect[key] > snappedCoord ? GRAPH_CELL_SIZE / 2 : -GRAPH_CELL_SIZE / 2)
    );
  });

  return {
    x1: snapRect.x1,
    y1: snapRect.y1,
    // Rect should at least be one cell size
    x2:
      Math.abs(snapRect.x2 - snapRect.x1) < GRAPH_CELL_SIZE
        ? snapRect.x1 + GRAPH_CELL_SIZE
        : snapRect.x2,
    y2:
      Math.abs(snapRect.y2 - snapRect.y1) < GRAPH_CELL_SIZE
        ? snapRect.y1 + GRAPH_CELL_SIZE
        : snapRect.y2,
  };
}

/**
 * Calculates the center point of a rectangle.
 *
 * @param {Object} rect - The rectangle coordinates with x1, y1, x2, and y2 properties.
 * @return {Object} The center coordinates of the rectangle.
 */
export function getRectCenter(rect) {
  return {
    x: (rect.x1 + rect.x2) / 2,
    y: (rect.y1 + rect.y2) / 2,
  };
}

export const snapPolygon = (polygon) => {
  const snappedRect = snapRect(AppUtils.makeBoundingBoxFromPolygon(polygon));
  return {
    position: getRectCenter(snappedRect),
    polygon: AppUtils.makeRectanglePolygon(snappedRect),
  };
};

/**
 * Converts a polygon to an array of cell positions.
 *
 * @param {Array} polygon - An array of points representing the polygon.
 * @return {Array} An array containing the cell positions of the bounding box corners.
 */

export const polygonToCellPositions = (polygon) => {
  const { x1, x2, y1, y2 } = AppUtils.makeBoundingBoxFromPolygon(polygon);

  return [
    toCellCoord({ x: x1 + GRAPH_CELL_SIZE / 2, y: y1 + GRAPH_CELL_SIZE / 2 }),
    toCellCoord({ x: x2 - GRAPH_CELL_SIZE / 2, y: y2 - GRAPH_CELL_SIZE / 2 }),
  ];
};

/**
 * Converts a polygon to an array of cell positions.
 *
 * @param {Array} polygon - An array of points representing the polygon.
 * @return {Array} An array containing the cell positions of the bounding box corners.
 */
export const getPolygonCells = (polygon) => {
  const { x1, x2, y1, y2 } = AppUtils.makeBoundingBoxFromPolygon(polygon);
  const width = Math.ceil((x2 - x1) / GRAPH_CELL_SIZE);
  const height = Math.ceil((y2 - y1) / GRAPH_CELL_SIZE);

  return Array.from({ length: height }, (_, y) =>
    Array.from({ length: width }, (_, x) =>
      snapGeomCoord({
        x: x1 + x * GRAPH_CELL_SIZE,
        y: y1 + y * GRAPH_CELL_SIZE,
      })
    )
  ).flat();
};

export const keyByPosition = ({ x, y }) => `${x}-${y}`;

export const isIntersectionsAllowed = (node) =>
  getNodeSnapType(node.data()) !== NODE_SNAP_TYPE.center ||
  node.data('isTrace');

export const snapNodePistionByType = (node, nodeSnapType) =>
  cr(
    [
      nodeSnapType === NODE_SNAP_TYPE.borders && node.polygon,
      () => snapPolygon(node.polygon),
    ],
    [
      nodeSnapType === NODE_SNAP_TYPE.center && node.position,
      () => ({
        position: snapGeomCoord(node.position),
      }),
    ],
    [nodeSnapType === NODE_SNAP_TYPE.none, () => ({ position: node.position })],
    [true, { position: undefined }]
  );

export const getSnapNodePosition = (node) =>
  snapNodePistionByType(node, getNodeSnapType(node));

/**
 * Snap the position of a node depending on its type.
 * @param {Object} node - The node to snap.
 */
export const snapNode = (node) =>
  node.position(getSnapNodePosition(node.data()));

/**
 * Moves a group of nodes in a graph by a specified shift in position.
 *
 * @param {Object} options - The options for moving the nodes.
 * @param {Object} options.cy - The Cypher graph object.
 * @param {Array} options.nodes - The nodes to be moved.
 * @param {Object} options.occupiedPositions - The positions that are already occupied.
 * @param {Object} options.positionShift - The shift in position to move the nodes by.
 * @param {Object} options.nodesStartPositions - The starting positions of the nodes.
 * @param {Function} [options.callback] - The callback function to be called after the nodes are moved.
 * @return {void}
 */
export const moveNodes = ({
  cy,
  nodes,
  occupiedPositions,
  positionShift,
  nodesStartPositions,
  callback,
}) => {
  if (!cy || !positionShift || !nodes.length) return;

  const nodesMap = keyBy(nodes, (node) => node.id());

  const newPositionsMap = mapValues(nodesMap, (node) => ({
    x: nodesStartPositions[node.id()].x + positionShift.x,
    y: nodesStartPositions[node.id()].y + positionShift.y,
  }));

  const canMove =
    isEmpty(occupiedPositions) ||
    !find(newPositionsMap, (i) => Boolean(occupiedPositions[keyByPosition(i)]));

  if (!canMove) return;

  nodes.forEach((node) => {
    const prevLocked = node.locked();
    if (prevLocked) node.unlock();
    node.position(newPositionsMap[node.id()]);
    if (prevLocked) node.lock();
    node.trigger('drag');
  });

  cy.collection(nodes).trigger('drag');
  cy.trigger(CUSTOM_EVENTS.drag_bulk, cy.collection(nodes));
  callback?.({ nodes });
};

/**
 * Get the cursor position on the graph based on the given Cy and event parameters.
 *
 * @param {object} cy - The Cy object representing the graph.
 * @param {object} e - The event object containing the original event.
 * @return {object} The cursor position on the graph as an object with x and y coordinates.
 */
export const getGraphCursorPosition = (cy, e) => {
  const evt = e.originalEvent;

  // Get the graph coordinates
  const zoom = cy.zoom();
  const pan = cy.pan();
  const containerRect = cy.container().getBoundingClientRect();

  // Calculate cursor position on the graph
  const cursorX = evt.clientX - containerRect.left - pan.x;
  const cursorY = evt.clientY - containerRect.top - pan.y;

  // Get cursor position on the graph
  return {
    x: cursorX / zoom,
    y: cursorY / zoom,
  };
};

/**
 * Finds the intersection of positions of nodes between two arrays of nodes.
 *
 * @param {Array<Object>} nodesA - An array of nodes with position property.
 * @param {Array<Object>} nodesB - An array of nodes with position property.
 * @return {Array<Object>} An array of nodes that have the same position in both arrays.
 */
export const findNodesPositionsIntersection = (nodesA, nodesB) => {
  return intersectionWith(nodesA, nodesB, (a, b) =>
    isEqual(a.position, b.position)
  );
};
